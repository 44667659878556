import styled from "@emotion/styled";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  MouseEvent,
  useMemo,
  useRef,
} from "react";
import ButtonComponent from "../Button";
import { DocumentIcon } from "@heroicons/react/24/outline";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  onClear?: () => void;
};

const StyledInput = styled("input")(() => ({
  visibility: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  width: 0,
  height: 0,
}));

const Button = styled(ButtonComponent)(() => ({
  position: "absolute",
  right: 8,
  top: 8,
}));

const Wrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled }) => ({
  padding: "1rem",
  borderRadius: 30,
  outline: "none",
  borderWidth: 1,
  height: 50,
  borderStyle: "solid",
  borderColor: "rgba(0,0,0,0.3)",
  transition: "border-color 0.2s ease-in-out",
  "&:focus": {
    borderColor: "rgba(0,0,0,1)",
  },
  cursor: "pointer",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "1rem",
  span: {
    fontSize: 13,
  },
  ...(disabled && {
    opacity: 0.5,
  }),
}));

const FileInput: FC<Props> = (props) => {
  const { onClear, disabled, ...rest } = props;
  const ref = useRef<HTMLInputElement>(null);

  const fileDescription = useMemo(() => {
    if (ref.current === undefined) {
      return "Datei auswählen";
    }
    if (ref.current === null) {
      return "Datei auswählen";
    }
    if (ref.current.files === undefined) {
      return "Datei auswählen";
    }
    if (ref.current.files === null) {
      return "Datei auswählen";
    }
    if (ref.current.files.length === 0) {
      return "Datei auswählen";
    }
    if (ref.current.files.length === 1) {
      return ref.current.files[0].name;
    }
    return `${ref.current.files.length} Dateien ausgewählt`;
  }, []);

  const hasFiles = useMemo(() => {
    if (ref.current === undefined) {
      return false;
    }
    if (ref.current === null) {
      return false;
    }
    if (ref.current.files === undefined) {
      return false;
    }
    if (ref.current.files === null) {
      return false;
    }
    return ref.current.files.length !== 0;
  }, []);

  const handleClear = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (ref.current) {
      ref.current.value = "";
    }
    if (onClear !== undefined) {
      onClear();
    }
  };

  return (
    <Wrapper
      onClick={(e) => {
        if (ref.current) {
          ref.current.click();
        }
      }}
      disabled={disabled}
    >
      <StyledInput disabled={disabled} type="file" ref={ref} {...rest} />
      <DocumentIcon width={16} height={16} color={"#333"} />
      <span>{fileDescription}</span>
      {hasFiles && (
        <Button onClick={handleClear}>
          <XMarkIcon width={16} height={16} />
        </Button>
      )}
    </Wrapper>
  );
};

export default FileInput;
