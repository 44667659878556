import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

type IUseBreakPoint = {
  isMobile: boolean;
};

const BreakpointContext = createContext({} as IUseBreakPoint);
export const useBreakpoint = () => useContext(BreakpointContext);
const useBreakpointProvider = (): IUseBreakPoint => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 480);
    });
    window.addEventListener("load", () => {
      setIsMobile(window.innerWidth < 480);
    });
  }, []);

  return { isMobile };
};
export const BreakpointProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const value = useBreakpointProvider();
  return (
    <BreakpointContext.Provider value={value}>
      {children}
    </BreakpointContext.Provider>
  );
};

export default BreakpointProvider;
