import styled from "@emotion/styled";

const Container = styled("div")(() => ({
  width: "clamp(300px, 80%, 1280px)",
  "@media (max-width: 768px)": {
    width: "clamp(300px, 90%, 1280px)",
  },
  margin: "0 auto",
}));

export default Container;
