import { FC, useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { AnimatePresence } from "framer-motion";
import Backdrop from "./modal/Backdrop";
import Modal from "./modal/Modal";
import Input from "./forms/Input";
import FileInput from "./forms/FileInput";
import Button from "./Button";
import Portal from "./page/Portal";
import { useForm } from "react-hook-form";
import { Sponsor as SponsorClass, useSponsoren } from "../providers/Sponsoren";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import styled from "@emotion/styled";

type Props = {
  open: boolean;
  onClose: () => void;
};

const FormTitle = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem",
  h5: {
    margin: 0,
  },
}));

const CreateSponsorModal: FC<Props> = ({ open, onClose }) => {
  const { loggedIn } = useAuth();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<SponsorClass>({
    resolver: classValidatorResolver(SponsorClass),
  });
  const { createSponsor } = useSponsoren();
  const [file, setFile] = useState<File | undefined>(undefined);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);

  const submit = async (data: SponsorClass) => {
    if (!loggedIn) {
      return;
    }
    try {
      await createSponsor(data, file);
      onClose();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  if (loggedIn) {
    return (
      <Portal>
        <AnimatePresence>
          {open && (
            <Backdrop onClick={onClose}>
              <Modal>
                <form onSubmit={handleSubmit(submit)}>
                  <FormTitle>
                    <h5>Sponsor hinzufügen</h5>
                    <Input
                      {...register("name")}
                      errors={errors}
                      placeholder={"Name"}
                    />
                    <Input
                      {...register("imageScale", {
                        setValueAs: (value) => parseFloat(value),
                      })}
                      type={"number"}
                      errors={errors}
                      step={0.1}
                      min={0.1}
                      max={2}
                      placeholder={"Bild Skalierung"}
                    />
                    <FileInput
                      onChange={(e) => {
                        if (e.target.files !== null) {
                          if (e.target.files.length > 0) {
                            setFile(e.target.files[0]);
                          } else {
                            setFile(undefined);
                          }
                        } else {
                          setFile(undefined);
                        }
                      }}
                    />
                    <Button size={"large"} fullWidth type={"submit"}>
                      Speichern
                    </Button>
                  </FormTitle>
                </form>
              </Modal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    );
  }

  return null;
};

export default CreateSponsorModal;
