import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import styled from "@emotion/styled";
import LoadingBox from "../components/LoadingBox";
import { useBreakpoint } from "./Breakpoint";

type IUseAuthProvider = {
  user: User | null;
  loggedIn: boolean;
  validating: boolean;
  signOut: () => Promise<void>;
};

const AuthContext = createContext({} as IUseAuthProvider);
export const useAuth = () => useContext(AuthContext);
const useAuthProvider = (): IUseAuthProvider => {
  const [user, setUser] = useState<User | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [validating, setValidating] = useState<boolean>(true);

  useEffect(() => {
    let cancel = false;

    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (!cancel) {
        setUser(user);
        setLoggedIn(!!user);
        setValidating(false);
      }
    });

    return () => {
      cancel = true;
      unsubscribe();
    };
  }, []);

  const signOut = useCallback(async () => {
    setValidating(true);
    try {
      await getAuth().signOut();
      setLoggedIn(false);
      setUser(null);
    } catch {
      setLoggedIn(false);
      setUser(null);
    } finally {
      setValidating(false);
    }
  }, []);

  return {
    user,
    loggedIn,
    validating,
    signOut,
  };
};
const LoadingPage = styled("main")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  backgroundColor: "#001c39",
  height: "100vh",
}));

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useAuthProvider();
  const { isMobile } = useBreakpoint();
  return (
    <AuthContext.Provider value={value}>
      {value.validating ? (
        <LoadingPage>
          <LoadingBox
            width={isMobile ? 50 : 200}
            height={isMobile ? 50 : 200}
          />
        </LoadingPage>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};
