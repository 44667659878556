import { forwardRef } from "react";
import styled from "@emotion/styled";
import Switch from "./Switch";

type Props = {
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
  disabled?: boolean;
};

const Wrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ disabled }) => ({
  display: "flex",
  flexDirection: "row",
  position: "relative",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: 30,
  borderWidth: 1,
  borderColor: "rgba(0,0,0,0.3)",
  padding: "0 1rem",
  height: 50,
  borderStyle: "solid",
  cursor: "pointer",
  span: {
    fontSize: 13,
    fontWeight: 600,
  },
  ...(disabled && {
    opacity: 0.5,
  }),
}));

const Checkbox = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, label, disabled }, ref) => {
    const handleClick = () => {
      if (disabled) {
        return;
      }
      onChange(!value);
    };
    return (
      <Wrapper disabled={disabled} onClick={handleClick}>
        <span>{label}</span>
        <Switch
          variant={"dark"}
          ref={ref}
          value={value}
          onChange={onChange}
          width={50}
          height={30}
        />
      </Wrapper>
    );
  }
);

export default Checkbox;
