import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import Header from "./components/page/Header";
import Footer from "./components/page/Footer";
import ScrollToTop from "./components/page/ScrollToTop";
import Projekte from "./pages/projekte/Projekte";
import Projekt from "./pages/projekte/Projekt";
import Profil from "./pages/Profil";
import Events from "./pages/events/Events";
import { FC, lazy, Suspense, useEffect } from "react";
import { useCookies } from "./providers/Cookie";
import styled from "@emotion/styled";
import { AnimatePresence } from "framer-motion";
import Sponsoren from "./pages/sponsoren/Sponsoren";
import Portal from "./components/page/Portal";
import LoadingBox from "./components/LoadingBox";
import GoogleMapsInfo from "./pages/GoogleMapsInfo";
import { useBreakpoint } from "./providers/Breakpoint";

const Impressum = lazy(() => import("./pages/Impressum"));
const Datenschutz = lazy(() => import("./pages/Datenschutz"));
const Kurse = lazy(() => import("./pages/kurse/Kurse"));
const Kontakt = lazy(() => import("./pages/Kontakt"));
const Event = lazy(() => import("./pages/events/Event"));
const Sponsor = lazy(() => import("./pages/sponsoren/Sponsor"));
const Verein = lazy(() => import("./pages/verein/Verein"));

const CookieProtect = styled("div", {
  shouldForwardProp: (prop) => prop !== "accepted",
})<{ accepted: boolean }>(({ accepted }) => ({
  ...(!accepted && {
    "::after": {
      content: '""',
      backdropFilter: "blur(20px)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
  }),
}));

const LoadingPageWrapper = styled("div")(() => ({
  position: "fixed",
  zIndex: 1000,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LoadingPage: FC = () => {
  const { isMobile } = useBreakpoint();
  return (
    <Portal>
      <LoadingPageWrapper>
        <LoadingBox
          width={isMobile ? 50 : 100}
          height={isMobile ? 50 : 100}
          color={"#333"}
        />
      </LoadingPageWrapper>
    </Portal>
  );
};

function App() {
  const { pathname } = useLocation();
  const { neccecary } = useCookies();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <CookieProtect accepted={neccecary}>
        <Header />
        <main>
          <AnimatePresence mode={"wait"}>
            <Routes>
              <Route path={"/"} element={<Home />} />
              <Route path={"/kontakt"} element={<Kontakt />} />
              <Route path={"/impressum"} element={<Impressum />} />
              <Route path={"/datenschutz"} element={<Datenschutz />} />
              <Route path={"/kurse"} element={<Kurse />} />
              <Route path={"/profil"} element={<Profil />} />
              <Route path={"/verein"} element={<Verein />} />
              <Route path={"/events"}>
                <Route index element={<Events />} />
                <Route path={":id"} element={<Event />} />
              </Route>
              <Route path={"/sponsoren"}>
                <Route index element={<Sponsoren />} />
                <Route path={":id"} element={<Sponsor />} />
              </Route>
              <Route path={"/projekte"}>
                <Route index element={<Projekte />} />
                <Route path={":id"} element={<Projekt />} />
              </Route>
              <Route path={"/google-maps-info"} element={<GoogleMapsInfo />} />
              <Route path={"*"} element={<div>404</div>} />
            </Routes>
          </AnimatePresence>
        </main>
        <Footer />
        <ScrollToTop />
      </CookieProtect>
    </Suspense>
  );
}

export default App;
