import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";
import { useAuth } from "./AuthProvider";
import { useCookies } from "./Cookie";
import { IsBoolean, IsNumber, IsString, MinLength } from "class-validator";
import { v4 } from "uuid";

type IUseProjekte = {
  projekte: Projekt[];
  createProjekt: (projekt: Projekt, image?: File) => Promise<void>;
  deleteProjekt: (id: string) => Promise<void>;
  getImage: (id: string) => Promise<string>;
  updateProjekt: (id: string, projekt: Projekt) => Promise<void>;
  updateProjektImage: (id: string, image: File) => Promise<void>;
  uploadProjektImage: (
    id: string,
    image: File,
    description?: string
  ) => Promise<void>;
  getProjektFiles: (id: string) => Promise<ProjektFile[]>;
  deleteProjektFile: (id: string, image: ProjektFile) => Promise<void>;
  uploadProjektVideo: (
    id: string,
    video: File,
    description?: string
  ) => Promise<void>;
  getThumbnail: (id: string) => Promise<string>;
};

export type ProjektFile = {
  fullPath: string;
  url: string;
  type: string;
  thumbnailSmall?: string;
  thumbnailLarge?: string;
  description?: string;
};

export class Projekt {
  id: string;
  @IsString({ message: "Titel muss ein String sein" })
  @MinLength(3, { message: "Titel muss mindestens 3 Zeichen lang sein" })
  title: string;

  @IsString({ message: "Untertitel muss ein String sein" })
  @MinLength(3, { message: "Untertitel muss mindestens 3 Zeichen lang sein" })
  description: string;

  @IsString({ message: "Tag muss ein String sein" })
  @MinLength(3, { message: "Tag muss mindestens 3 Zeichen lang sein" })
  tag: string;

  @IsNumber({}, { message: "Lesezeit muss eine Zahl sein" })
  readTime: number;

  @IsBoolean({ message: "isHero muss ein Boolean sein" })
  isHero: boolean;

  shouldShowImage?: boolean;
  created?: string;
}

const ProjekteContext = createContext({} as IUseProjekte);
export const useProjekte = () => useContext(ProjekteContext);
const useProjekteProvider = (): IUseProjekte => {
  const [projekte, setProjekte] = useState<Projekt[]>([]);
  const { loggedIn } = useAuth();
  const { neccecary } = useCookies();

  useEffect(() => {
    const firestore = getFirestore();
    const q = query(
      collection(firestore, "projekte"),
      orderBy("created", "desc")
    );
    const unsubscribeProjekte = onSnapshot(q, (snapshot) => {
      const projekte: Projekt[] = [];
      snapshot.forEach((doc) => {
        projekte.push({
          id: doc.id,
          ...doc.data(),
        } as Projekt);
      });
      setProjekte(projekte);
    });

    return () => {
      unsubscribeProjekte();
    };
  }, []);

  const deleteFileDescription = useCallback(async (name: string) => {
    const firestore = getFirestore();
    const q = query(
      collection(firestore, "projekt_file_descriptions"),
      where("filename", "==", name)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      return;
    }
    const docRef = querySnapshot.docs[0];
    await deleteDoc(docRef.ref);
  }, []);

  const createProjekt = useCallback(
    async (projekt: Projekt, image?: File) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }

      const newDoc = await addDoc(collection(getFirestore(), "projekte"), {
        title: projekt.title,
        description: projekt.description,
        tag: projekt.tag,
        readTime: projekt.readTime,
        isHero: false,
        shouldShowImage: false,
        created: serverTimestamp(),
      });

      if (image) {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          `projekte/${newDoc.id}/${newDoc.id}.jpg`
        );
        await uploadBytes(storageRef, image);
        await updateDoc(newDoc, {
          shouldShowImage: true,
        });
      }
    },
    [loggedIn, neccecary]
  );

  const deleteProjekt = useCallback(
    async (id: string) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      const firestore = getFirestore();
      await deleteDoc(doc(firestore, "projekte", id));
      const storage = getStorage();
      await deleteObject(ref(storage, `projekte/${id}`));
    },
    [loggedIn, neccecary]
  );

  const updateProjekt = useCallback(
    async (id: string, projekt: Projekt) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      return updateDoc(doc(getFirestore(), "projekte", id), {
        title: projekt.title,
        description: projekt.description,
        tag: projekt.tag,
        readTime: projekt.readTime,
        isHero: projekt.isHero,
      });
    },
    [loggedIn, neccecary]
  );

  const updateProjektImage = useCallback(
    async (id: string, image: File) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      const storage = getStorage();
      const storageRef = ref(storage, `projekte/${id}/${id}.jpg`);
      await uploadBytes(storageRef, image);
      await updateDoc(doc(getFirestore(), "projekte", id), {
        shouldShowImage: true,
      });
    },
    [loggedIn, neccecary]
  );

  const getThumbnail = useCallback(
    async (id: string) => {
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      return getDownloadURL(
        ref(getStorage(), `projekte/${id}/${id}_200x200.jpg`)
      );
    },
    [neccecary]
  );

  const getImage = useCallback(
    async (id: string) => {
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      return getDownloadURL(ref(getStorage(), `projekte/${id}/${id}.jpg`));
    },
    [neccecary]
  );

  const uploadProjektImage = useCallback(
    async (id: string, image: File, description?: string) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      const storage = getStorage();
      const name = `${v4()}.jpg`;
      const storageRef = ref(storage, `projekte/${id}/${name}`);
      await uploadBytes(storageRef, image);
      if (description) {
        await addDoc(collection(getFirestore(), "projekt_file_descriptions"), {
          filename: name,
          description,
        });
      }
    },
    [loggedIn, neccecary]
  );

  const getProjektFiles = useCallback(
    async (id: string) => {
      if (!neccecary) {
        throw new Error("Not neccecary");
      }

      const getThumbnailSmall = async (name: string) => {
        try {
          const url = await getDownloadURL(
            ref(getStorage(), `projekte/${id}/${name}_200x200.jpg`)
          );
          return url;
        } catch {
          return undefined;
        }
      };

      const getThumbnailLarge = async (name: string) => {
        try {
          const url = await getDownloadURL(
            ref(getStorage(), `projekte/${id}/${name}_800x800.jpg`)
          );
          return url;
        } catch {
          return undefined;
        }
      };

      const listRef = ref(getStorage(), `projekte/${id}`);
      const result = await listAll(listRef);
      const files: ProjektFile[] = [];

      for (const item of result.items) {
        if (item.name === `${id}.jpg`) continue;
        if (item.name.includes("_200x200")) continue;
        if (item.name.includes("_800x800")) continue;

        const metaData = await getMetadata(item);

        const file: ProjektFile = {
          fullPath: item.fullPath,
          url: await getDownloadURL(item),
          thumbnailSmall: await getThumbnailSmall(item.name.split(".")[0]),
          thumbnailLarge: await getThumbnailLarge(item.name.split(".")[0]),
          type: metaData.contentType || "",
        };

        const q = query(
          collection(getFirestore(), "projekt_file_descriptions"),
          where("filename", "==", item.name)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const docRef = querySnapshot.docs[0];
          const data = docRef.data();
          file.description = data.description;
        }

        files.push(file);
      }
      return files.sort((a, b) => {
        // sort videos and images in groups, always 3 images, 1 video and so on
        if (a.type.includes("video") && b.type.includes("image")) {
          return 1;
        }
        if (a.type.includes("image") && b.type.includes("video")) {
          return -1;
        }
        return 0;
      });
    },
    [neccecary]
  );

  const deleteProjektFile = useCallback(
    async (id: string, image: ProjektFile) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      const storage = getStorage();
      const imageRef = ref(storage, image.fullPath);
      await deleteObject(imageRef);
      await deleteFileDescription(image.fullPath);
    },
    [deleteFileDescription, loggedIn, neccecary]
  );

  const uploadProjektVideo = useCallback(
    async (id: string, video: File, description?: string) => {
      if (!loggedIn) {
        throw new Error("Not logged in");
      }
      if (!neccecary) {
        throw new Error("Not neccecary");
      }
      const storage = getStorage();
      const name = `${v4()}.${video.name.split(".").pop()}`;
      const storageRef = ref(storage, `projekte/${id}/${name}`);
      await uploadBytes(storageRef, video);
      if (!description) {
        const firestore = getFirestore();
        await addDoc(collection(firestore, "projekt_file_descriptions"), {
          filename: name,
          description,
        });
      }
    },
    [loggedIn, neccecary]
  );

  return {
    projekte,
    uploadProjektVideo,
    getProjektFiles,
    uploadProjektImage,
    createProjekt,
    updateProjekt,
    updateProjektImage,
    deleteProjekt,
    deleteProjektFile,
    getImage,
    getThumbnail,
  };
};

export const ProjekteProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useProjekteProvider();

  return (
    <ProjekteContext.Provider value={value}>
      {children}
    </ProjekteContext.Provider>
  );
};
