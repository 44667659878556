import styled from "@emotion/styled";
import { FC, Fragment, useState } from "react";
import PanelCaption from "../../../components/PanelCaption";
import Sponsor from "./Sponsor";
import { useSponsoren } from "../../../providers/Sponsoren";
import CreateSponsorModal from "../../../components/CreateSponsorModal";

const Wrapper = styled("div")(() => ({
  gap: "1rem",
  width: "100%",
  display: "grid",
  gridTemplatColumns: "repeat(1, 1ft)",
  "@media (min-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (min-width: 1024px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "@media (min-width: 1280px)": {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  paddingBottom: 100,
}));

const Sponsoren: FC = () => {
  const { sponsoren } = useSponsoren();
  const [addSponsorModal, setAddSponsorModal] = useState<boolean>(false);

  return (
    <Fragment>
      <PanelCaption to={"/sponsoren"} onAdd={() => setAddSponsorModal(true)}>
        Unsere Partner
      </PanelCaption>
      <Wrapper>
        {sponsoren.map((sponsor) => (
          <Sponsor sponsor={sponsor} key={sponsor.id} />
        ))}
      </Wrapper>
      <CreateSponsorModal
        open={addSponsorModal}
        onClose={() => setAddSponsorModal(false)}
      />
    </Fragment>
  );
};

export default Sponsoren;
