import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { FC, ReactNode } from "react";

type Size = "large";

const ModalStyled = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size?: Size }>(({ size }) => ({
  position: "relative",
  borderRadius: "30px",
  backgroundColor: "white",
  width: "clamp(300px, 90%, 600px)",
  ...(size === "large" && {
    width: "clamp(300px, 90%, 800px)",
  }),
  display: "flex",
  flexDirection: "column",
  maxHeight: "80vh",
  overflowY: "auto",
}));

type Props = {
  size?: Size;
  children: ReactNode;
};

const Modal: FC<Props> = ({ size, children }) => (
  <ModalStyled
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    size={size}
    onClick={(e) => e.stopPropagation()}
  >
    {children}
  </ModalStyled>
);

export default Modal;
