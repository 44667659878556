import styled from "@emotion/styled";
import {motion} from "framer-motion";
import {forwardRef} from "react";

type Props = {
    value: boolean;
    onChange: (value: boolean) => void;
    width: number;
    height: number;
    variant?: "dark" | "light";
};

const Wrapper = styled(motion.div, {
    shouldForwardProp: (prop) =>
        prop !== "width" && prop !== "height" && prop !== "variant",
})<{
    width: number;
    height: number;
    variant?: "dark" | "light" | "green";
}>(({width, height, variant}) => ({
    backgroundColor: "rgba(255,255,255,0.4)",
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    cursor: "pointer",
    width,
    height,
    position: "relative",
    ...(variant === "dark" && {
        backgroundColor: "rgba(0,0,0,0.2)",
    }),
    ...(variant === "green" && {
        backgroundColor: "rgba(0,255,0,0.4)",
    }),
}));

const Handle = styled(motion.div, {
    shouldForwardProp: (prop) =>
        prop !== "width" && prop !== "height" && prop !== "variant" && prop !== "checked",
})<{ width: number; height: number; variant?: "dark" | "light" | "green", checked: boolean }>(
    ({width, height, variant, checked}) => ({
        position: "absolute",
        width,
        height,
        top: height * 0.14,
        left: 0,
        backgroundColor: "rgba(255,255,255,1)",
        borderRadius: 999999,
        transition: "all 0.2s ease-in-out",
        ...(variant === "dark" && {
            backgroundColor: "rgba(0,0,0,0.5)",
            ...(checked && {
                backgroundColor: "rgba(255,255,255,1)",
            }),
        }),
        ...(variant === "green" && {
            backgroundColor: "rgba(70, 219, 111, 0.8)",
            ...(checked && {
                backgroundColor: "rgba(70, 219, 111, 1)",
            }),
        }),
    })
);

const Switch = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <Wrapper
            width={props.width}
            height={props.height}
            variant={props.variant}
            onClick={(e) => {
                e.stopPropagation();
                props.onChange(!props.value);
            }}
            ref={ref}
        >
            <Handle
                variant={props.variant}
                checked={props.value}
                width={props.height * 0.8}
                height={props.height * 0.8}
                animate={{
                    x: props.value ? props.width - props.height : props.height * 0.15,
                }}
                transition={{duration: 0.05}}
            />
        </Wrapper>
    );
});

export default Switch;
