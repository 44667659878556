import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { FC, ReactNode } from "react";

type Props = {
  onClick?: () => void;
  children?: ReactNode;
};

const BackdropStyled = styled(motion.div)(() => ({
  position: "fixed",
  zIndex: 1000,
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0,0,0,0.6)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backdropFilter: "blur(8px)",
}));

const Backdrop: FC<Props> = ({ onClick, children }) => (
  <BackdropStyled
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    onClick={onClick}
  >
    {children}
  </BackdropStyled>
);

export default Backdrop;
