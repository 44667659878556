import { FC, Fragment, useState } from "react";
import { useProjekte } from "../../providers/Projekte";
import Container from "../../components/Container";
import ProjektComponent from "../../components/projekte/ProjektComponent";
import styled from "@emotion/styled";
import banner from "../../assets/erock.jpg";
import Banner from "../../components/banner/Banner";
import CreateProjektModal from "../../components/CreateProjektModal";

const Wrapper = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
  gap: "1rem",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  "@media (max-width: 480px)": {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  },
}));

const Projekte: FC = () => {
  const { projekte } = useProjekte();
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <Fragment>
      <Banner
        title={"Projekte"}
        background={banner}
        subtitle={
          "Hier findest du alle Projekte, die wir bisher umgesetzt haben."
        }
        onCreate={() => setShowAddModal(true)}
      />
      <Container style={{ marginTop: -100, paddingBottom: 100 }}>
        <Wrapper>
          {projekte.map((projekt) => (
            <ProjektComponent key={projekt.id} {...projekt} />
          ))}
        </Wrapper>
      </Container>
      <CreateProjektModal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
      />
    </Fragment>
  );
};

export default Projekte;
