import { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Projekt as ProjektType,
  ProjektFile,
  useProjekte,
} from "../../providers/Projekte";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import Container from "../../components/Container";
import Button from "../../components/Button";
import { useAuth } from "../../providers/AuthProvider";
import { useCookies } from "../../providers/Cookie";
import styled from "@emotion/styled";
import { TrashIcon } from "@heroicons/react/24/solid";
import UpdateProjektModal from "../../components/UpdateProjektModal";
import UpdateProjektFilesModal from "../../components/UpdateProjektFilesModal";
import LoadingBox from "../../components/LoadingBox";
import Banner from "../../components/banner/Banner";
import { useBreakpoint } from "../../providers/Breakpoint";
import { motion } from "framer-motion";

const FileContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "count",
})<{
  count: number;
}>(({ count }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
  columnGap: "1rem",
  rowGap: "2rem",
  paddingBottom: 100,
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  },
  ...(count === 1 && {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  }),
}));

const File = styled("div", {
  shouldForwardProp: (prop) => prop !== "type" && prop !== "count",
})<{
  type: string;
  count: number;
}>(({ type, count }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  gridColumn: "span 1",
  "@media (min-width: 768px)": {
    gridColumn: type.startsWith("video") ? "span 2" : "span 1",
  },
  "@media (min-width: 1024px)": {
    gridColumn: type.startsWith("video") ? "span 3" : "span 1",
  },
  ...(count === 1 && {
    gridColumn: "span 1",
  }),
}));

const FileWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "type",
})<{
  type: string;
}>(({ type }) => ({
  position: "relative",
  height: type.startsWith("video") ? 500 : 300,
}));

const Video = styled("video")(() => ({
  width: "100%",
  height: "100%",
  gridColumn: "span 1",
  objectFit: "cover",
  borderRadius: 20,
}));

const PreviewImage = styled(motion.img)(() => ({
  width: "100%",
  height: "100%",
  gridColumn: "span 1",
  objectFit: "cover",
  borderRadius: 20,
}));

const DeleteButton = styled(Button)(() => ({
  position: "absolute",
  top: "1rem",
  right: "1rem",
  backgroundColor: "rgb(255,0,0)",
}));

const LoadingWrapper = styled("div")(() => ({ width: "100%", height: "100%" }));

const Description = styled("p")(() => ({
  fontSize: 13,
  margin: 0,
  padding: "0 0.5rem",
}));

const FilesLoadingWrapper = styled("div")(() => ({
  width: "100%",
  height: 600,
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const Projekt: FC = () => {
  const { id } = useParams();
  const [projekt, setProjekt] = useState<ProjektType | null>(null);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<string | null>(null);
  const [imageError, setImageError] = useState<boolean>(false);
  const [files, setFiles] = useState<ProjektFile[]>([]);
  const [filesLoading, setFilesLoading] = useState<boolean>(true);
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(false);
  const { getProjektFiles, deleteProjektFile, getImage, deleteProjekt } =
    useProjekte();
  const [filesError, setFilesError] = useState<boolean>(false);
  const { loggedIn } = useAuth();
  const { neccecary } = useCookies();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [filesModal, setFilesModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    if (!neccecary) {
      return;
    }

    let cancel = false;

    if (id) {
      const unsubscribe = onSnapshot(
        doc(getFirestore(), "projekte", id),
        (snapshot) => {
          if (!cancel) {
            setProjekt({ ...snapshot.data(), id: snapshot.id } as ProjektType);
            setLoading(false);

            getImage(id)
              .then((url) => {
                if (!cancel) {
                  setUrl(url);
                }
              })
              .catch(() => {
                if (!cancel) {
                  setImageError(true);
                }
              });

            getProjektFiles(id)
              .then((images) => {
                if (!cancel) {
                  setFiles(images);
                }
              })
              .catch(() => {
                if (!cancel) {
                  setFilesError(true);
                }
              })
              .finally(() => {
                if (!cancel) {
                  setFilesLoading(false);
                }
              });
          }
        }
      );

      return () => {
        cancel = true;
        unsubscribe();
      };
    } else {
      setLoading(false);
    }

    return () => {
      cancel = true;
    };
  }, [getImage, getProjektFiles, id, neccecary]);

  const handleDelete = (image: ProjektFile) => {
    if (window.confirm("Bild wirklich löschen?")) {
      handleDeleteImage(image)
        .then(() => {})
        .catch(console.log);
    }
  };

  const handleDeleteImage = async (image: ProjektFile) => {
    if (!id) {
      return;
    }
    setDeleteDisabled(true);
    try {
      await deleteProjektFile(id, image);
    } catch (error) {
      console.log(error);
    } finally {
      const newImages = await getProjektFiles(id);
      setFiles(newImages);
      setDeleteDisabled(false);
    }
  };

  const onDelete = async () => {
    if (!id) {
      return;
    }
    if (!projekt) {
      return;
    }
    if (window.confirm("Projekt wirklich löschen?")) {
      try {
        await deleteProjekt(id);
        navigate(-1);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (loading) {
    return (
      <LoadingWrapper>
        <LoadingBox width={200} height={200} color={"#000"} />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      {url !== null && (
        <Banner
          title={projekt?.title}
          background={url}
          onEdit={() => setEditModal(true)}
          backgroundError={imageError}
          onDelete={onDelete}
        />
      )}
      <Container style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <p style={{ marginTop: 0 }}>{projekt?.description}</p>
        <h2>Bilder</h2>
        {loggedIn && (
          <Button type={"button"} onClick={() => setFilesModal(true)}>
            Bild hinzufügen
          </Button>
        )}
      </Container>
      {filesError && (
        <Container>
          <p>
            Es ist ein Fehler beim Laden der Bilder aufgetreten. Bitte versuchen
            Sie es später erneut.
          </p>
        </Container>
      )}
      {filesLoading && (
        <FilesLoadingWrapper>
          <LoadingBox width={100} height={100} color={"#333"} />
        </FilesLoadingWrapper>
      )}
      <FileContainer count={files.length}>
        {files.map((file) => (
          <File count={files.length} type={file.type} key={file.fullPath}>
            <FileWrapper type={file.type}>
              {file.type.startsWith("video") && (
                <Video src={file.url} controls />
              )}
              {file.type.startsWith("image") && (
                <PreviewImage
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  src={
                    isMobile
                      ? file.thumbnailSmall ?? file.url
                      : file.thumbnailLarge ?? file.url
                  }
                  alt={projekt?.title + "-" + file.fullPath}
                />
              )}
              {loggedIn && (
                <DeleteButton
                  variant={"dark"}
                  disabled={deleteDisabled}
                  onClick={() => handleDelete(file)}
                >
                  <TrashIcon width={20} height={20} color={"#fff"} />
                </DeleteButton>
              )}
            </FileWrapper>
            {file.description !== undefined && (
              <Description>{file.description}</Description>
            )}
          </File>
        ))}
      </FileContainer>
      {projekt !== null && (
        <Fragment>
          <UpdateProjektModal
            open={editModal}
            onClose={() => setEditModal(false)}
            projekt={projekt}
          />
          <UpdateProjektFilesModal
            open={filesModal}
            onClose={() => setFilesModal(false)}
            projekt={projekt}
            setFiles={setFiles}
          />
        </Fragment>
      )}
    </div>
  );
};

export default Projekt;
