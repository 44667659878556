import { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Projekt as ProjektType, useProjekte } from "../../providers/Projekte";
import { Link as RouterLink } from "react-router-dom";
import { useCookies } from "../../providers/Cookie";
import { PhotoIcon } from "@heroicons/react/24/solid";
import styled from "@emotion/styled";

const Link = styled(RouterLink)(() => ({
  position: "relative",
  gridColumn: "span 1",
  borderRadius: "12px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  textDecoration: "none",
  color: "#000",
}));

const ImageWrapper = styled("div")(() => ({
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  overflow: "hidden",
  width: "100%",
  height: "400px",
}));

const Image = styled(motion.img)(() => ({
  height: 400,
  width: "100%",
  objectFit: "cover",
}));

const BlankImage = styled(motion.div)(() => ({
  height: "400px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#fff",
}));

const ImageError = styled("div")(() => ({
  height: 400,
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  p: {
    color: "rgba(0,0,0,.5)",
  },
}));

const Body = styled("div")(() => ({
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  height: "8rem",
}));

const Caption = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
}));

const Lesezeit = styled("p")(() => ({
  fontWeight: "normal",
  fontSize: "11px",
  margin: 0,
  color: "rgba(0,0,0,.5)",
  position: "absolute",
  bottom: "1rem",
  lineHeight: "1rem",
  right: "1rem",
}));

const Tag = styled("p")(() => ({
  fontWeight: "bold",
  fontSize: "10px",
  lineHeight: "1rem",
  margin: 0,
  position: "absolute",
  bottom: "1rem",
  left: "1rem",
}));

const Description = styled("p")(() => ({
  margin: 0,
  color: "rgba(0,0,0,.5)",
  fontSize: 12,
  lineHeight: "1rem",
  maxLines: 3,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

const Title = styled("p")(() => ({
  margin: 0,
  fontSize: 20,
  lineHeight: "2rem",
  maxLines: 1,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

const ProjektComponent: FC<ProjektType> = (props) => {
  const { getImage, getThumbnail } = useProjekte();
  const [isHover, setIsHover] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [imageError, setImageError] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const { neccecary } = useCookies();

  useEffect(() => {
    if (!neccecary) {
      return;
    }

    if (props.shouldShowImage === undefined || !props.shouldShowImage) {
      if (image !== null) {
        setImage(null);
      }
      return;
    }

    let cancel = false;

    Promise.all([getThumbnail(props.id), getImage(props.id)])
      .then(([thumb, image]) => {
        if (!cancel) {
          setUrl(thumb);
          setImage(image);
        }
      })
      .catch(() => {
        if (!cancel) {
          setImageError(true);
        }
      });

    return () => {
      cancel = true;
    };
  }, [
    getThumbnail,
    neccecary,
    props.id,
    props.shouldShowImage,
    image,
    getImage,
  ]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Link
      aria-label={props.title}
      to={`/projekte/${props.id}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ImageWrapper>
        {!imageError && url !== null && (
          <Image
            initial={{ scale: 1.3 }}
            animate={{
              scale: isHover ? 1.03 : 1,
              transition: { delay: 0.1, duration: 0.4 },
            }}
            src={url}
            alt={props.title}
            onLoad={() => {
              if (image === null) {
                return;
              }
              if (url === image) {
                return;
              }
              setUrl(image);
            }}
          />
        )}
        {(props.shouldShowImage === undefined || !props.shouldShowImage) && (
          <BlankImage
            initial={{ scale: 1.3 }}
            animate={{ scale: isHover ? 1.05 : 1, transition: { delay: 0.1 } }}
            transition={{ duration: 0.6 }}
          >
            <PhotoIcon width={30} height={30} color={"rgba(0,0,0,0.6)"} />
          </BlankImage>
        )}
        {imageError && (
          <ImageError>
            <p>Bild nicht gefunden</p>
          </ImageError>
        )}
      </ImageWrapper>
      <Body>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
        <Caption>
          <Tag>{props.tag}</Tag>
          <Lesezeit>Lesezeit: {props.readTime} min</Lesezeit>
        </Caption>
      </Body>
    </Link>
  );
};

export default ProjektComponent;
