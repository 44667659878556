import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { getRemoteConfig } from "firebase/remote-config";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { ProjekteProvider } from "./providers/Projekte";
import { EventsProvider } from "./providers/Events";
import { AuthProvider } from "./providers/AuthProvider";
import BreakpointProvider from "./providers/Breakpoint";
import { CookiesProvider } from "./providers/Cookie";
import { KontaktProvider } from "./providers/Kontakt";
import { SponsorenProvider } from "./providers/Sponsoren";
import "moment/locale/de";
import moment from "moment";

moment.locale("de");

const firebaseConfig = {
  apiKey: "AIzaSyBVt7ZGlQtL36fuTmg54uTxTRSFRbBy3-w",
  authDomain: "til-98690.firebaseapp.com",
  projectId: "til-98690",
  storageBucket: "til-98690.appspot.com",
  messagingSenderId: "634591711888",
  appId: "1:634591711888:web:88313db0688f7dc8903e15",
  measurementId: "G-Q03H7EQPNN",
};

initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig();
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000,
  fetchTimeoutMillis: 60000,
};
remoteConfig.defaultConfig = {
  HOME_HERO_HEIGHT: 800,
  HOME_HERO_INTERVAL: 8000,
  BANNER_HEIGHT: 600,
  BANNER_MOBILE_HEIGHT: 400,
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BreakpointProvider>
      <CookiesProvider>
        <BrowserRouter>
          <AuthProvider>
            <ProjekteProvider>
              <EventsProvider>
                <SponsorenProvider>
                  <KontaktProvider>
                    <App />
                  </KontaktProvider>
                </SponsorenProvider>
              </EventsProvider>
            </ProjekteProvider>
          </AuthProvider>
        </BrowserRouter>
      </CookiesProvider>
    </BreakpointProvider>
  </React.StrictMode>
);
reportWebVitals();
