import { FC, useEffect, useState } from "react";
import {
  Projekt,
  Projekt as ProjektType,
  useProjekte,
} from "../providers/Projekte";
import { useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useAuth } from "../providers/AuthProvider";
import { AnimatePresence } from "framer-motion";
import Backdrop from "./modal/Backdrop";
import Modal from "./modal/Modal";
import FileInput from "./forms/FileInput";
import Button from "./Button";
import Input from "./forms/Input";
import Checkbox from "./forms/Checkbox";
import Portal from "./page/Portal";
import styled from "@emotion/styled";

type Props = {
  open: boolean;
  onClose: () => void;
  projekt: Projekt;
};

const PreviewImage = styled("img")(() => ({
  width: "100%",
  height: 300,
  borderRadius: 20,
  objectFit: "cover",
}));

const FormBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem",
}));

const UpdateProjektModal: FC<Props> = ({ open, onClose, projekt }) => {
  const { updateProjekt, updateProjektImage } = useProjekte();
  const {
    handleSubmit,
    watch,
    setValue,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ProjektType>({
    resolver: classValidatorResolver(ProjektType),
  });
  const [image, setImage] = useState<File | undefined>(undefined);
  const { loggedIn } = useAuth();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);

  useEffect(() => {
    reset(projekt);
  }, [projekt, reset]);

  const handleSubmitProjekt = async (data: ProjektType) => {
    try {
      await updateProjekt(projekt.id, data);
      if (image !== undefined) {
        await updateProjektImage(projekt.id, image);
      }
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  if (loggedIn) {
    return (
      <Portal>
        <AnimatePresence>
          {open && (
            <Backdrop onClick={onClose}>
              <Modal>
                <form onSubmit={handleSubmit(handleSubmitProjekt)}>
                  <FormBody>
                    <Input
                      type={"text"}
                      placeholder={"Titel"}
                      {...register("title")}
                    />
                    <Input
                      type={"text"}
                      placeholder={"Beschreibung"}
                      {...register("description")}
                    />
                    <Input
                      type={"text"}
                      placeholder={"Tag"}
                      {...register("tag")}
                    />
                    <Input
                      type={"number"}
                      placeholder={"Lesezeit"}
                      {...register("readTime", { valueAsNumber: true })}
                      errors={errors}
                      step={0.5}
                      min={0.5}
                      max={100}
                    />
                    <Checkbox
                      label={"Auf der Startseite anzeigen?"}
                      value={watch("isHero")}
                      onChange={(v) => setValue("isHero", v)}
                    />
                    <FileInput
                      accept={"image/*,video/*"}
                      onClear={() => {
                        setImage(undefined);
                      }}
                      onChange={(e) => {
                        if (e.target.files) {
                          setImage(e.target.files[0]);
                        }
                      }}
                    />
                    {image !== undefined && (
                      <PreviewImage
                        src={URL.createObjectURL(image)}
                        alt={"Preview"}
                      />
                    )}
                    <Button
                      size={"large"}
                      fullWidth
                      disabled={isSubmitting}
                      type={"submit"}
                    >
                      Speichern
                    </Button>
                  </FormBody>
                </form>
              </Modal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    );
  }

  return null;
};

export default UpdateProjektModal;
