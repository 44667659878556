import { FC, Fragment, useEffect, useState } from "react";
import banner from "../../assets/saxonz.jpg";
import Banner from "../../components/banner/Banner";
import { Event as EventType, useEvents } from "../../providers/Events";
import ContainerComponent from "../../components/Container";
import Input from "../../components/forms/Input";
import Button from "../../components/Button";
import {
  CalendarIcon,
  CursorArrowRaysIcon,
  FunnelIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import LocationModal from "../../components/LocationModal";
import styled from "@emotion/styled";
import moment, { Moment } from "moment";
import CalendarModal from "../../components/CalendarModal";

type EventProps = {
  event: EventType;
  onLocation: (url: string) => void;
  search: string;
};

const Event: FC<EventProps> = (props) => {
  const [url, setUrl] = useState("");
  const { getThumbnail } = useEvents();
  const navigate = useNavigate();

  useEffect(() => {
    let cancel = false;
    getThumbnail(props.event.id)
      .then((url) => {
        if (!cancel) {
          setUrl(url);
        }
      })
      .catch(console.log);

    return () => {
      cancel = true;
    };
  }, [getThumbnail, props.event.id]);

  return (
    <EventWrapper>
      <EventMain>
        <EventImageWrapper>
          <EventImage src={url} alt={props.event.title} />
        </EventImageWrapper>
        <EventBody>
          <h4>{props.event.title}</h4>
          <h5>{props.event.description}</h5>
        </EventBody>
        <EventButtons>
          <Button
            onClick={() => navigate(`/events/${props.event.id}`)}
            type={"button"}
            aria-label={"Event anzeigen"}
            title={"Event anzeigen"}
            icon
            fullWidth
          >
            <CursorArrowRaysIcon width={24} height={24} />
            <span>Event anzeigen</span>
          </Button>
          <Button
            onClick={() => {
              if (props.event.locationUrl !== undefined) {
                props.onLocation(props.event.locationUrl);
              }
            }}
            fullWidth
            type={"button"}
            aria-label={"Location anzeigen"}
            title={"Location anzeigen"}
            icon
          >
            <MapIcon width={24} height={24} />
            <span>Karte anzeigen</span>
          </Button>
        </EventButtons>
      </EventMain>
      <EventTimes>
        {props.event.times
          .filter((time) => {
            if (props.search.length === 0) {
              return true;
            }
            const title = time.title.toLowerCase();
            const description = time.description.toLowerCase();
            const location = time.location.toLowerCase();

            return (
              title.includes(props.search) ||
              description.includes(props.search) ||
              location.includes(props.search)
            );
          })
          .map((time, index) => {
            return (
              <Time key={index}>
                <TimeDate>
                  <TimeDay>
                    {moment(time.date, "YYYY-MM-DD").format("DD")}
                  </TimeDay>
                  <TimeMonth>
                    {moment(time.date, "YYYY-MM-DD").format("MMMM YY")}
                  </TimeMonth>
                  <TimeTime>
                    {time.start} - {time.end} Uhr
                  </TimeTime>
                </TimeDate>
                <TimeBody>
                  <TimeTitle>{time.title}</TimeTitle>
                  <TimeDescription>{time.description}</TimeDescription>
                </TimeBody>
                <TimeLocationWrapper>
                  <TimeLocation>{time.location}</TimeLocation>
                </TimeLocationWrapper>
              </Time>
            );
          })}
      </EventTimes>
    </EventWrapper>
  );
};

const Events: FC = () => {
  const { events } = useEvents();
  const [search, setSearch] = useState("");
  const [showLocation, setShowLocation] = useState(false);
  const [locationUrl, setLocationUrl] = useState<string>("");
  const [calendar, setCalendar] = useState(false);
  const [filterDate, setFilterDate] = useState<Moment | null>(null);

  return (
    <Fragment>
      <Banner background={banner} title="Events" />
      <Container>
        <Header>
          <Input
            type={"search"}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={"Suchen"}
            wrapperStyle={{
              width: 300,
            }}
            wrapper768Style={{
              width: "100%",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              gap: "0.8rem",
            }}
          >
            {filterDate !== null && (
              <p
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  margin: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <FunnelIcon width={20} height={20} />
                {filterDate.format("DD.MM.YYYY")}
              </p>
            )}
            <Button
              icon
              type={"button"}
              title={"Datum"}
              aria-label={"Datum"}
              onClick={() => setCalendar(true)}
            >
              <CalendarIcon width={24} height={24} />
              <span>Kalender</span>
            </Button>
          </div>
        </Header>
        <EventsWrapper>
          {events
            .filter((event) => {
              if (filterDate === null) {
                return true;
              }
              return event.times.some(
                (time) =>
                  moment(time.date, "YYYY-MM-DD").format("DD.MM.YYYY") ===
                  filterDate.format("DD.MM.YYYY")
              );
            })
            .filter((event) => {
              if (search.length === 0) {
                return true;
              }
              const title = event.title.toLowerCase();
              const description = event.description.toLowerCase();
              const searchLower = search.toLowerCase();
              const timesTitles = event.times
                .map((time) => time.title.toLowerCase())
                .join(" ")
                .toLowerCase();
              const timesDescriptions = event.times
                .map((time) => time.description.toLowerCase())
                .join(" ")
                .toLowerCase();
              const timesLocations = event.times
                .map((time) => time.location.toLowerCase())
                .join(" ")
                .toLowerCase();

              return (
                title.includes(searchLower) ||
                description.includes(searchLower) ||
                timesTitles.includes(searchLower) ||
                timesDescriptions.includes(searchLower) ||
                timesLocations.includes(searchLower)
              );
            })
            .map((event) => (
              <Event
                key={event.id}
                event={event}
                search={search.toLowerCase()}
                onLocation={(url) => {
                  setLocationUrl(url);
                  setShowLocation(true);
                }}
              />
            ))}
        </EventsWrapper>
      </Container>
      <LocationModal
        open={showLocation}
        onClose={() => {
          setShowLocation(false);
          setLocationUrl("");
        }}
        locationUrl={locationUrl}
      />
      <CalendarModal
        date={filterDate}
        open={calendar}
        onClose={() => setCalendar(false)}
        onDateChange={setFilterDate}
      />
    </Fragment>
  );
};

const EventWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const EventMain = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  width: "100%",
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: "rgba(0,0,0,0.2)",
  borderStyle: "solid",
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
}));

const EventImageWrapper = styled("div")(() => ({
  width: 150,
  height: 150,
  position: "relative",
  flexShrink: 0,
  "@media (max-width: 768px)": {
    width: "100%",
    height: 150,
  },
}));
const Header = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "1rem",
  "@media (max-width: 768px)": {
    paddingBottom: "2rem",
    gap: "0.5rem",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
}));

const EventBody = styled("div")(() => ({ paddingLeft: "1rem", flexGrow: 1 }));
const EventButtons = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.7rem",
  "@media (max-width: 768px)": {
    paddingBottom: "1rem",
  },
}));
const EventImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));
const EventTimes = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));
const Container = styled(ContainerComponent)(() => ({
  gap: "1rem",
  display: "flex",
  flexDirection: "column",
  padding: "100px 0",
  "@media (max-width: 768px)": {
    padding: "50px 0",
  },
}));
const Time = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  borderWidth: 0,
  borderBottomWidth: 1,
  borderColor: "rgba(0,0,0,0.3)",
  borderStyle: "solid",
  "@media (max-width: 768px)": {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
}));

const TimeDate = styled("div")(() => ({
  width: 150,
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  padding: "1rem",
}));

const TimeMonth = styled("p")(() => ({
  margin: 0,
  textAlign: "center",
  fontWeight: 400,
  fontSize: 16,
}));
const TimeDay = styled("p")(() => ({
  margin: 0,
  textAlign: "center",
  fontWeight: 700,
  fontSize: 20,
}));

const TimeTime = styled("p")(() => ({
  margin: 0,
  marginTop: "1rem",
  fontSize: 12,
  color: "rgba(0,0,0,0.6)",
  textAlign: "center",
}));

const TimeBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  padding: "0.5rem 0",
  gap: "0.2rem",
}));

const TimeTitle = styled("p")(() => ({
  fontWeight: 400,
  margin: 0,
  fontSize: 14,
  color: "rgba(0,0,0,0.5)",
}));
const TimeLocationWrapper = styled("div")(() => ({
  flexShrink: 0,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  paddingRight: "1rem",
}));
const TimeLocation = styled("p")(() => ({
  margin: 0,
  fontWeight: 600,
  fontSize: 16,
}));
const EventsWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "5rem",
}));
const TimeDescription = styled("p")(() => ({ fontWeight: 600, margin: 0 }));
export default Events;
