import styled from "@emotion/styled";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  MouseEvent,
} from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

type Variant = "clean" | "dark" | "danger" | "success" | "white" | undefined;

const StyledButton = styled("button", {
  shouldForwardProp: (props) =>
    props !== "variant" &&
    props !== "size" &&
    props !== "fullWidth" &&
    props !== "icon",
})<{ variant?: Variant; size?: "large"; fullWidth?: boolean; icon?: boolean }>(
  ({ variant, size, fullWidth, icon }) => ({
    padding: "0.5rem",
    borderRadius: 30,
    outline: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "rgba(0,0,0,0.3)",
    transition: "all 0.2s ease-in-out",
    cursor: "pointer",
    backgroundColor: "transparent",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: fullWidth ? "100%" : "auto",
    ...(icon && {
      gap: "0.4rem",
    }),
    "&:focus": {
      borderColor: "rgba(0,0,0,1)",
    },
    ...(variant === "clean" && {
      borderWidth: 0,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.1)",
      },
    }),
    ...(variant === "dark" && {
      backgroundColor: "rgba(0,0,0,0.5)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.3)",
      },
    }),
    ...(variant === "danger" && {
      backgroundColor: "rgba(255,0,0,0.5)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(255,0,0,0.7)",
      },
    }),
    ...(variant === "success" && {
      backgroundColor: "rgba(72, 194, 66,0.7)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(72, 194, 66,0.5)",
      },
    }),
    ...(variant === "white" && {
      backgroundColor: "#fff",
      color: "#000",
      "&:hover": {
        backgroundColor: "rgba(240,240,240,1)",
      },
    }),
    ...(size === "large" && {
      padding: "1rem 2rem",
      fontSize: 14,
    }),
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  })
);

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: Variant;
  size?: "large";
  fullWidth?: boolean;
  loading?: boolean;
  icon?: boolean;
};

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { children, loading, disabled, onClick, icon, ...rest } = props;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (loading !== undefined && loading) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <StyledButton
      ref={ref}
      icon={icon}
      {...rest}
      onClick={handleClick}
      disabled={disabled}
    >
      {loading !== undefined && loading ? (
        <ArrowPathIcon width={10} height={10} color={"rgba(0,0,0,0.5)"} />
      ) : (
        children
      )}
    </StyledButton>
  );
});

export default Button;
