import { FC, useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { AnimatePresence } from "framer-motion";
import Backdrop from "./modal/Backdrop";
import Modal from "./modal/Modal";
import Input from "./forms/Input";
import Checkbox from "./forms/Checkbox";
import FileInput from "./forms/FileInput";
import Button from "./Button";
import Portal from "./page/Portal";
import { useForm } from "react-hook-form";
import { Projekt, useProjekte } from "../providers/Projekte";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import styled from "@emotion/styled";
import FormBody from "./forms/FormBody";

type Props = {
  open: boolean;
  onClose: () => void;
};

const PreviewImage = styled("img")(() => ({
  width: "100%",
  height: 300,
  borderRadius: 20,
  objectFit: "cover",
}));

const CreateProjektModal: FC<Props> = ({ open, onClose }) => {
  const { loggedIn } = useAuth();
  const { createProjekt } = useProjekte();
  const [image, setImage] = useState<File | undefined>(undefined);
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<Projekt>({
    resolver: classValidatorResolver(Projekt),
    defaultValues: {
      id: "",
      description: "",
      isHero: false,
      readTime: 1,
      tag: "",
      title: "",
    },
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);

  const handleSubmitProjekt = async (projekt: Projekt) => {
    try {
      await createProjekt(projekt, image);
      onClose();
      reset();
      setImage(undefined);
    } catch (error) {
      console.error(error);
    }
  };

  if (loggedIn) {
    return (
      <Portal>
        <AnimatePresence>
          {open && (
            <Backdrop onClick={onClose}>
              <Modal>
                <form onSubmit={handleSubmit(handleSubmitProjekt)}>
                  <p>Neues Projekt erstellen</p>
                  <FormBody>
                    <Input
                      type={"text"}
                      placeholder={"Titel"}
                      {...register("title")}
                      errors={errors}
                      disabled={isSubmitting}
                    />
                    <Input
                      type={"text"}
                      placeholder={"Beschreibung"}
                      {...register("description")}
                      errors={errors}
                      disabled={isSubmitting}
                    />
                    <Input
                      type={"text"}
                      placeholder={"Tag"}
                      {...register("tag")}
                      errors={errors}
                      disabled={isSubmitting}
                    />
                    <Input
                      type={"number"}
                      placeholder={"Lesezeit"}
                      {...register("readTime", { valueAsNumber: true })}
                      errors={errors}
                      step={0.5}
                      min={0.5}
                      max={100}
                      disabled={isSubmitting}
                    />
                    <Checkbox
                      label={"Auf der Startseite anzeigen?"}
                      value={watch("isHero")}
                      onChange={(v) => setValue("isHero", v)}
                      disabled={isSubmitting}
                    />
                    <FileInput
                      accept={"image/*"}
                      onChange={(e) => {
                        if (e.target.files) {
                          setImage(e.target.files[0]);
                        }
                      }}
                      onClear={() => setImage(undefined)}
                      disabled={isSubmitting}
                    />
                    {image !== undefined && (
                      <PreviewImage
                        src={URL.createObjectURL(image)}
                        alt={"Preview"}
                      />
                    )}
                    <Button
                      size={"large"}
                      fullWidth
                      disabled={isSubmitting}
                      type={"submit"}
                    >
                      Speichern
                    </Button>
                  </FormBody>
                </form>
              </Modal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    );
  }

  return null;
};

export default CreateProjektModal;
