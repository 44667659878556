import styled from "@emotion/styled";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, useScroll, motion } from "framer-motion";
import { FC, useEffect, useState } from "react";

const Button = styled(motion.button)(() => ({
  position: "fixed",
  zIndex: 1,
  borderRadius: "30px",
  color: "#fff",
  backgroundColor: "#222",
  border: "none",
  width: 50,
  height: 50,
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.5rem 1rem",
  bottom: "1rem",
  right: "1rem",
  "@media (max-width: 1200px)": {
    bottom: "24px",
    right: "24px",
  },
}));

const ScrollToTop: FC = () => {
  const { scrollY } = useScroll({ offset: [100, 0] });
  const [show, setShow] = useState(false);

  useEffect(() => {
    const unsubscribe = scrollY.on("change", (v) => {
      if (v > 150) {
        setShow(true);
      } else {
        setShow(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [scrollY]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <AnimatePresence>
      {show && (
        <Button
          type="button"
          title="Zurück nach oben scrollen"
          whileHover={{ scale: 1.1 }}
          initial={{ x: 400 }}
          animate={{ x: 0, transition: { stiffness: 100, damping: 50 } }}
          exit={{ x: 400 }}
          onClick={handleScrollToTop}
        >
          <ChevronUpIcon color={"#fff"} width={24} height={24} />
        </Button>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTop;
