import styled from "@emotion/styled";
import { FC, MouseEvent, ReactNode } from "react";
import ButtonComponent from "./Button";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { useBreakpoint } from "../providers/Breakpoint";

const Wrapper = styled("div")(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "space-between",
  alignItems: "center",
}));

const PanelCaptionStyled = styled("h2")(() => ({
  display: "block",
  color: "rgb(26,25,25)",
  fontSize: "40px",
  fontWeight: 700,
  position: "relative",
}));

const Button = styled(ButtonComponent)(() => ({
  backgroundColor: "rgba(0,0,0,0.3)",
  borderRadius: "50%",
  border: "none",
}));

const PanelCaptionLinkStyled = styled(Link)(() => ({
  display: "block",
  color: "rgb(26,25,25)",
  fontSize: "40px",
  fontWeight: 700,
  position: "relative",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
  cursor: "pointer",
}));

type Props = {
  children: ReactNode;
  onAdd?: () => void;
  to?: string;
};

const PanelCaption: FC<Props> = ({ children, onAdd, to }) => {
  const { loggedIn } = useAuth();
  const { isMobile } = useBreakpoint();

  const handleOnAdd = (e: MouseEvent<HTMLButtonElement>) => {
    if (onAdd === undefined) {
      return;
    }
    e.stopPropagation();
    onAdd();
  };

  return (
    <Wrapper>
      {to === undefined && <PanelCaptionStyled>{children}</PanelCaptionStyled>}
      {to !== undefined && (
        <PanelCaptionLinkStyled to={to}>
          <PanelCaptionStyled>{children}</PanelCaptionStyled>
        </PanelCaptionLinkStyled>
      )}
      {onAdd !== undefined && loggedIn && !isMobile && (
        <Button aria-label={"add"} onClick={handleOnAdd}>
          <PlusIcon width={20} height={20} color={"#fff"} />
        </Button>
      )}
    </Wrapper>
  );
};

export default PanelCaption;
