import styled from "@emotion/styled";

const ErrorText = styled("p")(() => ({
  color: "red",
  margin: 0,
  fontSize: 12,
  paddingLeft: "1rem",
}));

export default ErrorText;
