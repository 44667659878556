import styled from "@emotion/styled";

const FormBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: "1rem",
}));

export default FormBody;
