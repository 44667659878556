import { FC, ReactNode } from "react";
import styled from "@emotion/styled";
import ContainerComponent from "../Container";

type Props = {
  text: string;
  subtitle?: string;
  children?: ReactNode;
};

const Title = styled("h1")(() => ({
  margin: 0,
  fontSize: 70,
  color: "#fff",
  "@media (max-width: 768px)": {
    fontSize: 50,
  },
}));

const Container = styled(ContainerComponent)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  height: "100%",
}));

const Subtitle = styled("h2")(() => ({
  fontSize: 30,
  "@media (max-width: 768px)": {
    fontSize: 20,
  },
  fontWeight: 400,
  color: "rgba(255, 255, 255, 0.8)",
  margin: 0,
}));

const BannerTitle: FC<Props> = ({ text, subtitle, children }) => (
  <Container>
    <Title>{text}</Title>
    {subtitle !== undefined && <Subtitle>{subtitle}</Subtitle>}
    {children}
  </Container>
);

export default BannerTitle;
