import {createContext, FC, ReactNode, useCallback, useContext} from "react";
import {getFunctions, httpsCallable} from "firebase/functions"
import {IsBoolean, IsEmail, IsString, MinLength} from "class-validator";

export class Formular {
    @IsString({message: "Bitte gib einen Namen ein"})
    @MinLength(3, {message: "Der Name muss mindestens 3 Zeichen lang sein"})
    name: string;
    @IsEmail({}, {message: "Bitte gib eine gültige E-Mail Adresse ein"})
    email: string;
    @IsString({message: "Bitte gib einen Betreff ein"})
    @MinLength(10, {message: "Der Betreff muss mindestens 10 Zeichen lang sein"})
    message: string;
    @IsBoolean({message: "Bitte akzeptiere die Datenschutzerklärung"})
    privacyAccepted: boolean;
}

type IUseKontakt = {
    sendFormular: (formular: Formular, captchaToken: string) => Promise<void>;
}

const KontaktContext = createContext({} as IUseKontakt);
export const useKontakt = () => useContext(KontaktContext);
const useKontaktProvider = (): IUseKontakt => {
    const sendFormular = useCallback(async (formular: Formular, captchaToken: string) => {
        if (!formular.privacyAccepted) {
            throw new Error("Bitte akzeptiere die Datenschutzerklärung");
        }
        const callable = httpsCallable(getFunctions(), "sendFormular");
        await callable({...formular, captchaToken});
    }, [])

    return {
        sendFormular
    }
}
export const KontaktProvider: FC<{ children: ReactNode }> = ({children}) => {
    return (
        <KontaktContext.Provider value={useKontaktProvider()}>
            {children}
        </KontaktContext.Provider>
    )
}