import { motion } from "framer-motion";
import { FC } from "react";
import styled from "@emotion/styled";

type Props = {
  width: number;
  height: number;
  color?: string;
};

const Box = styled(motion.div, {
  shouldForwardProp: (prop) =>
    prop !== "color" && prop !== "height" && prop !== "width",
})<Props>(({ color, width, height }) => ({
  backgroundColor: color || "#fff",
  width,
  height,
}));

const LoadingBox: FC<Props> = ({ width, height, color }) => {
  return (
    <Box
      width={width}
      height={height}
      color={color}
      animate={{
        scale: [1, 2, 2, 1, 1],
        rotate: [0, 0, 180, 180, 0],
        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
        times: [0, 0.2, 0.5, 0.8, 1],
        repeat: Infinity,
        repeatDelay: 1,
      }}
    />
  );
};

export default LoadingBox;
