import styled from "@emotion/styled";
import ContainerStyled from "../Container";
import { Link } from "react-router-dom";
import { FC } from "react";
import { useCookies } from "../../providers/Cookie";

const StyledFooter = styled("footer")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#00162b",
  color: "#fff",
}));

const FooterNavigation = styled("nav")(() => ({
  display: "grid",
  flexGrow: 1,
  gap: "0.8rem",
  gridTemplateColumns: "repeat(1, 1fr)",
  "@media (min-width: 768px)": {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

const FooterCosmos = styled("div")(() => ({
  color: "rgba(255,255,255,0.5)",
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  "& p": {
    fontSize: "inherit",
    color: "inherit",
    margin: 0,
  },
}));

const Container = styled(ContainerStyled)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "flex-start",
  paddingTop: "52px",
  paddingBottom: "52px",
}));

const NavLinkWrapper = styled("div")(() => ({
  gridColumn: "span 1",
}));

const NavLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "none",
  fontSize: 16,
  fontWeight: 600,
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Button = styled("button")(() => ({
  color: "#fff",
  cursor: "pointer",
  outline: "none",
  backgroundColor: "transparent",
  fontSize: 16,
  fontWeight: 600,
  padding: 0,
  border: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

const Footer: FC = () => {
  const { setShowCookieSettings } = useCookies();

  return (
    <StyledFooter>
      <Container>
        <FooterNavigation>
          <NavLinkWrapper>
            <NavLink to="/verein">Verein</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to="/kontakt">Kontakt</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <Button onClick={() => setShowCookieSettings(true)}>
              Cookie Einstellungen
            </Button>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to="/datenschutz">Datenschutzrichtlinien</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to="/agb">AGB</NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to={"/impressum"}>Impressum</NavLink>
          </NavLinkWrapper>
        </FooterNavigation>
        <FooterCosmos>
          <p>© 84til {new Date().getFullYear()} - All rights reserved</p>
        </FooterCosmos>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
