import { FC } from "react";
import Container from "../components/Container";
import step1 from "../assets/step1.jpg";
import step2 from "../assets/step2.jpg";
import step3 from "../assets/step3.jpg";
import styled from "@emotion/styled";
import { useAuth } from "../providers/AuthProvider";
import { Navigate } from "react-router-dom";

const Title = styled("h1")(() => ({
  marginTop: "20vh",
}));

const List = styled("ul")(() => ({}));

const ListItem = styled("li")(() => ({
  padding: "3rem 0",
}));

const Image = styled("img")(() => ({
  width: "60%",
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

const GoogleMapsInfo: FC = () => {
  const { loggedIn } = useAuth();

  if (!loggedIn) {
    return <Navigate to={"/"} />;
  }

  return (
    <Container>
      <Title>Google Maps Info</Title>
      <h3>
        In diesem Tutorial findest du eine Beschreibung über das Einbinden von
        Google Maps in deine Website:
      </h3>
      <List>
        <ListItem>
          <p>1. Google Maps öffnen und den Standort suchen</p>
          <Image src={step1} alt={"Schritt 1"} title={"Schritt 1"} />
        </ListItem>
        <ListItem>
          <p>
            2. Klicke auf <b>"Karte einbetten"</b> und auf{" "}
            <b>"HTML KOPIEREN"</b>
          </p>
          <Image src={step2} alt={"Schritt 2"} title={"Schritt 2"} />
        </ListItem>
        <ListItem>
          <p>
            3. Füge den kopierten Text in das Feld "Standort" im Formular ein.
            <br />
            <b>Ist der Link falsch kopiert, wird die Karte NICHT angezeigt</b>
          </p>
          <Image src={step3} alt={"Schritt 3"} title={"Schritt 3"} />
        </ListItem>
      </List>
    </Container>
  );
};

export default GoogleMapsInfo;
