import { FC, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import {
  Sponsor as SponsorType,
  useSponsoren,
} from "../../../providers/Sponsoren";

type Props = {
  sponsor: SponsorType;
};

const Link = styled(RouterLink)(() => ({
  display: "flex",
  flexDirection: "row",
  textDecoration: "none",
  color: "#000",
}));

const Image = styled("img", { shouldForwardProp: (prop) => prop !== "scale" })<{
  scale?: string;
}>(({ scale }) => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  scale,
}));

const Wrapper = styled(motion.div)(() => ({
  height: 200,
  gridColumn: "span 1",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  borderRadius: 10,
  overflow: "hidden",
  cursor: "pointer",
}));

const Sponsor: FC<Props> = ({ sponsor }) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const { getImage } = useSponsoren();

  useEffect(() => {
    let cancel = false;

    getImage(sponsor.id)
      .then((url) => {
        if (!cancel) {
          setUrl(url);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      cancel = true;
    };
  }, [getImage, sponsor.id]);

  return (
    <Link to={`/sponsoren/${sponsor.id}`} aria-label={sponsor.name}>
      <Wrapper whileHover={{ scale: 1.02 }}>
        <Image src={url} scale={sponsor.imageScale?.toString() || "1"} />
      </Wrapper>
    </Link>
  );
};

export default Sponsor;
