import styled from "@emotion/styled";
import { FC } from "react";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import { AnimatePresence, motion } from "framer-motion";

const ImageStyled = styled(motion.img, {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "mobileHeight",
})<{ height: number; mobileHeight: number }>(({ height, mobileHeight }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height,
  objectFit: "cover",
  zIndex: -2,
  "@media (max-width: 768px)": {
    height: mobileHeight,
  },
}));

type Props = {
  src: string;
};

const BannerImage: FC<Props> = ({ src }) => {
  const height = getValue(getRemoteConfig(), "BANNER_HEIGHT").asNumber();
  const mobileHeight = getValue(
    getRemoteConfig(),
    "BANNER_MOBILE_HEIGHT"
  ).asNumber();

  return (
    <AnimatePresence>
      <ImageStyled
        alt={"Banner"}
        src={src}
        height={height}
        mobileHeight={mobileHeight}
      />
    </AnimatePresence>
  );
};

export default BannerImage;
