import { FC } from "react";
import Portal from "./page/Portal";
import { AnimatePresence } from "framer-motion";
import Backdrop from "./modal/Backdrop";
import styled from "@emotion/styled";
import Button from "./Button";
import { XMarkIcon } from "@heroicons/react/24/solid";

type Props = {
  open: boolean;
  onClose: () => void;
  locationUrl: string;
};

const IFrame = styled("iframe")(() => ({
  width: "clamp(300px, 90%, 800px)",
  height: "clamp(300px, 90%, 500px)",
  border: "none",
  borderRadius: 20,
}));

const LocationModal: FC<Props> = (props) => {
  return (
    <Portal>
      <AnimatePresence>
        {props.open && (
          <Backdrop onClick={props.onClose}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "clamp(300px, 90%, 800px)",
                }}
              >
                <Button
                  type={"button"}
                  onClick={props.onClose}
                  aria-label={"Close Location"}
                  title={"Close Location"}
                >
                  <XMarkIcon color={"#fff"} width={20} height={20} />
                </Button>
              </div>
              <IFrame
                src={props.locationUrl}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title={"Google Maps"}
                aria-label={"Google Maps"}
              />
            </div>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default LocationModal;
