import { FC } from "react";
import styled from "@emotion/styled";
import { getRemoteConfig, getValue } from "firebase/remote-config";

const BannerOverlayStyled = styled("div", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "mobileHeight",
})<{
  height: number;
  mobileHeight: number;
}>(({ height, mobileHeight }) => ({
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  height,
  zIndex: -1,
  background:
    "linear-gradient(180deg, rgba(0,15,30,0) 0%, rgba(0,15,30,0.5) 34%, rgba(0,15,30,0.8) 100%)",
  "@media (max-width: 768px)": {
    height: mobileHeight,
  },
}));

const BannerOverlay: FC = () => {
  const height = getValue(getRemoteConfig(), "BANNER_HEIGHT").asNumber();
  const mobileHeight = getValue(
    getRemoteConfig(),
    "BANNER_MOBILE_HEIGHT"
  ).asNumber();
  return <BannerOverlayStyled height={height} mobileHeight={mobileHeight} />;
};

export default BannerOverlay;
