import { ChangeEvent, ClipboardEvent, FC, useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import Portal from "./page/Portal";
import { AnimatePresence, motion } from "framer-motion";
import Backdrop from "./modal/Backdrop";
import { useForm } from "react-hook-form";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { Event, useEvents } from "../providers/Events";
import Modal from "./modal/Modal";
import Input from "./forms/Input";
import Textarea from "./forms/Textarea";
import FileInput from "./forms/FileInput";
import Button from "./Button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import styled from "@emotion/styled";
import ErrorText from "./forms/ErrorText";
import FormBody from "./forms/FormBody";

type Props = {
  open: boolean;
  onClose: () => void;
};

const IFrame = styled("iframe")(() => ({
  width: "100%",
  height: 300,
  border: "none",
  borderRadius: 20,
}));

const PreviewImage = styled("img")(() => ({
  width: "100%",
  borderRadius: 20,
  height: 300,
  objectFit: "cover",
}));

const TimesHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Time = styled(motion.li)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  padding: 0,
  margin: 0,
}));

const TimeDate = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "1rem",
}));

const TimesTitle = styled("p")(() => ({
  margin: 0,
  fontWeight: "bold",
  fontSize: 13,
}));

const TimesList = styled("ul")(() => ({
  margin: 0,
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const CreateEventModal: FC<Props> = ({ open, onClose }) => {
  const { loggedIn } = useAuth();
  const { createEvent } = useEvents();
  const [file, setFile] = useState<File | undefined>(undefined);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    setValue,
    watch,
    getValues,
  } = useForm<Event>({
    defaultValues: {
      description: "",
      id: "",
      subtitle: "",
      title: "",
      times: [],
    },
    resolver: classValidatorResolver(Event),
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);

  const submit = async (data: Event) => {
    try {
      await createEvent(data, file);
      reset();
      setFile(undefined);
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const url = event.currentTarget.value;
    setValue("locationUrl", url);
  };

  const handleLocationPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");
    if (text.includes("<iframe src=")) {
      const url = text.split("src=")[1].split(" ")[0].replaceAll('"', "");
      setValue("locationUrl", url);
      event.currentTarget.focus();
    } else {
      setValue("locationUrl", text);
      event.currentTarget.focus();
    }
  };

  if (loggedIn) {
    return (
      <Portal>
        <AnimatePresence>
          {open && (
            <Backdrop onClick={onClose}>
              <Modal size={"large"}>
                <form onSubmit={handleSubmit(submit)}>
                  <FormBody>
                    <TimesTitle>Allgemein</TimesTitle>
                    <Input
                      type={"text"}
                      {...register("title")}
                      errors={errors}
                      placeholder={"Titel des Events"}
                      disabled={isSubmitting}
                    />
                    <Input
                      type={"text"}
                      {...register("subtitle")}
                      errors={errors}
                      placeholder={"Kurzbeschreibung"}
                      disabled={isSubmitting}
                    />
                    <Textarea
                      {...register("description")}
                      errors={errors}
                      placeholder={"Beschreibung"}
                      disabled={isSubmitting}
                      rows={8}
                    />
                    <Input
                      value={watch("locationUrl")}
                      onChange={handleLocationChange}
                      onPaste={handleLocationPaste}
                      errors={errors}
                      placeholder={"Google-Link"}
                      label={"Standort"}
                      description={
                        "Kopiere hier den Link aus Google Maps, um den Standort des Events anzuzeigen."
                      }
                      onInfoClick={
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        "/google-maps-info"
                      }
                    />
                    <IFrame
                      src={watch("locationUrl")}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title={"Google Maps"}
                      aria-label={"Google Maps"}
                    />
                    <FileInput
                      accept={"image/*"}
                      multiple={false}
                      disabled={isSubmitting}
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          if (event.currentTarget.files.length > 0) {
                            setFile(event.currentTarget.files[0]);
                          }
                        }
                      }}
                    />
                    {file !== undefined && (
                      <PreviewImage
                        src={URL.createObjectURL(file)}
                        alt={"Vorschau"}
                      />
                    )}
                    <div>
                      <TimesHeader>
                        <TimesTitle>Ereigniszeitpunkte:</TimesTitle>
                        <Button
                          aria-label={"Zeitpunkt hinzufügen"}
                          title={"Zeitpunkt hinzufügen"}
                          type={"button"}
                          onClick={() => {
                            const times = getValues("times");
                            times.push({
                              date: moment().format("YYYY-MM-DD"),
                              start: moment().format("HH:mm"),
                              end: moment().format("HH:mm"),
                              title: "",
                              description: "",
                              location: "",
                            });
                            setValue("times", times);
                          }}
                        >
                          <PlusIcon width={14} height={14} color={"#000"} />
                        </Button>
                      </TimesHeader>
                      {errors.times && (
                        <ErrorText style={{ padding: 0 }}>
                          {errors.times.message}
                        </ErrorText>
                      )}
                    </div>
                    <TimesList>
                      <AnimatePresence mode={"popLayout"}>
                        {watch("times").map((time, index) => (
                          <Time
                            layout
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                            transition={{ type: "spring", duration: 0.25 }}
                            key={index}
                          >
                            <TimeDate>
                              <Input
                                type={"date"}
                                {...register(`times.${index}.date` as const)}
                                errors={errors}
                                placeholder={"Datum"}
                                disabled={isSubmitting}
                                label={"Datum"}
                              />
                              <Input
                                type={"time"}
                                {...register(`times.${index}.start` as const)}
                                errors={errors}
                                placeholder={"Start"}
                                disabled={isSubmitting}
                                label={"Start"}
                              />
                              <Input
                                type={"time"}
                                {...register(`times.${index}.end` as const)}
                                errors={errors}
                                placeholder={"Ende"}
                                disabled={isSubmitting}
                                label={"Ende"}
                              />
                              <Button
                                type={"button"}
                                variant={"danger"}
                                title={"Zeitpunkt entfernen"}
                                aria-label={"Zeitpunkt entfernen"}
                                onClick={() => {
                                  const times = getValues("times");
                                  times.splice(index, 1);
                                  setValue("times", times);
                                }}
                                style={{ marginBottom: "0.5rem" }}
                              >
                                <XMarkIcon
                                  width={14}
                                  height={14}
                                  color={"#fff"}
                                />
                              </Button>
                            </TimeDate>
                            <Input
                              type={"text"}
                              {...register(`times.${index}.title` as const)}
                              errors={errors}
                              placeholder={"Titel"}
                              disabled={isSubmitting}
                              label={"Titel"}
                            />
                            <Input
                              {...register(`times.${index}.location` as const)}
                              type={"text"}
                              errors={errors}
                              placeholder={"Ort"}
                              disabled={isSubmitting}
                              label={"Name des Ortes"}
                            />
                            <Textarea
                              placeholder={"Beschreibung"}
                              {...register(
                                `times.${index}.description` as const
                              )}
                              errors={errors}
                              disabled={isSubmitting}
                              rows={4}
                            />
                          </Time>
                        ))}
                      </AnimatePresence>
                    </TimesList>
                    <Button
                      disabled={isSubmitting}
                      size={"large"}
                      fullWidth
                      type={"submit"}
                      variant={"success"}
                    >
                      Erstellen
                    </Button>
                  </FormBody>
                </form>
              </Modal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    );
  }

  return null;
};

export default CreateEventModal;
