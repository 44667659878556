import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Container from "../../components/Container";
import Sponsoren from "./components/Sponsoren";
import styled from "@emotion/styled";
import { Projekt, useProjekte } from "../../providers/Projekte";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CreateProjektModal from "../../components/CreateProjektModal";
import PanelCaption from "../../components/PanelCaption";
import { useBreakpoint } from "../../providers/Breakpoint";
import CreateEventModal from "../../components/CreateEventModal";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import LoadingBox from "../../components/LoadingBox";
import { useEvents, Event } from "../../providers/Events";
import Button from "../../components/Button";
import mask from "../../assets/mask.svg";
import ProjektBaseComoponent from "../../components/projekte/ProjektComponent";
import {
  AnimatePresence,
  AnimationProps,
  MotionValue,
  motion,
  useMotionValue,
} from "framer-motion";
import { useCookies } from "../../providers/Cookie";
import { getRemoteConfig, getValue } from "firebase/remote-config";

let timer: NodeJS.Timeout | null = null;

type ProjekteProps = {
  HEIGHT: number;
  MOBILE_HEIGHT: number;
  INTERVAL: number;
};

type HomeHeroProps = {
  MOBILE_HEIGHT: number;
  HEIGHT: number;
  INTERVAL: number;
};

type HeroProjektComponentProps = {
  setIndex: Dispatch<SetStateAction<number>>;
  images: string[];
  index: number;
  i: number;
  projekt: Projekt;
  left: MotionValue<number>;
  HEIGHT: number;
  MOBILE_HEIGHT: number;
  INTERVAL: number;
  bottom: MotionValue<number>;
};

type ProjektItemProps = Event & {
  index: number;
  events: Event[];
  slideIndex: number;
};

type HeroLoadingProps = {
  children: ReactNode;
  HEIGHT: number;
  MOBILE_HEIGHT: number;
};

const HeroImageProps = (INTERVAL: number): AnimationProps => ({
  initial: { scale: 1.1, opacity: 1 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: INTERVAL / 1000,
    },
  },
  exit: { opacity: 0 },
});

const HeroTitleProps = (isCurrentIndex: boolean): AnimationProps => ({
  initial: {
    opacity: 0,
    x: "100%",
    y: -120,
  },
  animate: {
    opacity: isCurrentIndex ? 1 : 0,
    x: isCurrentIndex ? "0%" : "100%",
    transition: {
      duration: 0.5,
      delay: isCurrentIndex ? 0.5 : 0,
    },
    y: -120,
  },
  exit: {
    opacity: 0,
    x: "-50%",
    transition: {
      duration: 0.4,
      delay: 0.3,
    },
    y: -120,
  },
});

const HeroLinkTextProps = (isCurrentIndex: boolean): AnimationProps => ({
  initial: {
    opacity: 0,
    x: "100%",
    y: -20,
  },
  animate: {
    opacity: isCurrentIndex ? 1 : 0,
    x: isCurrentIndex ? "0%" : "100%",
    transition: {
      duration: 0.5,
      delay: isCurrentIndex ? 0.5 : 0,
    },
    y: -20,
  },
  exit: {
    opacity: 0,
    x: "-50%",
    transition: {
      delay: 0.1,
      duration: 0.4,
    },
    y: -20,
  },
});

const Projekte: FC<ProjekteProps> = ({ HEIGHT, MOBILE_HEIGHT, INTERVAL }) => {
  const [addProjektModal, setAddProjektModal] = useState<boolean>(false);
  const { projekte } = useProjekte();
  const { isMobile } = useBreakpoint();

  return (
    <Fragment>
      <PanelCaption to={"/projekte"} onAdd={() => setAddProjektModal(true)}>
        Unsere Projekte
      </PanelCaption>
      <ProjekteWrapper>
        {projekte.slice(0, 3).map((project) => (
          <ProjektBaseComoponent key={project.id} {...project} />
        ))}
        {isMobile && (
          <ShowAllWrapper>
            <MobileShowAll to={"/projekte"}>Alle Projekte</MobileShowAll>
          </ShowAllWrapper>
        )}
      </ProjekteWrapper>
      <CreateProjektModal
        open={addProjektModal}
        onClose={() => setAddProjektModal(false)}
      />
    </Fragment>
  );
};

const ProjektItemImageLoading: FC = () => {
  return (
    <ProjektItemNoImage>
      <LoadingBox width={30} height={30} color={"#fff"} />
    </ProjektItemNoImage>
  );
};

const ProjektItem: FC<ProjektItemProps> = (props) => {
  const [url, setUrl] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);
  const { getImage, getThumbnail } = useEvents();
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  useEffect(() => {
    let cancel = false;

    if (!props.shouldShowImage) {
      setImageLoading(false);
      return;
    }

    Promise.all([getImage(props.id), getThumbnail(props.id)])
      .then(([image, thumbnail]) => {
        if (!cancel) {
          setImage(image);
          setUrl(thumbnail);
        }
      })
      .catch(console.log)
      .finally(() => {
        if (!cancel) {
          setImageLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [getImage, getThumbnail, props.id, props.shouldShowImage]);

  const left = useMemo(() => {
    return props.index * 320;
  }, [props.index]);

  return (
    <ProjektLink to={`/events/${props.id}`}>
      <ProjektItemStyled
        left={left}
        animate={{
          x: -(props.slideIndex * 320),
          transition: { type: "spring", stiffness: 200, damping: 50 },
        }}
        whileHover={{ scale: 1.01, transition: { duration: 0.15 } }}
        whileTap={{ scale: 0.99, transition: { duration: 0.15 } }}
      >
        <ProjektItemImageWrapper>
          {url.length > 0 && !imageLoading && (
            <ProjektItemImage
              src={url}
              alt={props.title}
              onLoad={() => {
                if (image === null) {
                  return;
                }
                if (url === image) {
                  return;
                }
                setUrl(image);
              }}
            />
          )}
          {url.length === 0 && !imageLoading && <ProjektItemNoImage />}
          {imageLoading && <ProjektItemImageLoading />}
          <ProjektItemImageOverlay />
        </ProjektItemImageWrapper>
        <ProjektItemBody>
          <h1>{props.title}</h1>
        </ProjektItemBody>
      </ProjektItemStyled>
    </ProjektLink>
  );
};

const MoblileProjektItem: FC<Event> = (props) => {
  const [url, setUrl] = useState<string>("");
  const [image, setImage] = useState<string | null>(null);
  const { getImage, getThumbnail } = useEvents();
  const [imageLoading, setImageLoading] = useState<boolean>(true);

  useEffect(() => {
    let cancel = false;

    if (!props.shouldShowImage) {
      setImageLoading(false);
      return;
    }

    Promise.all([getImage(props.id), getThumbnail(props.id)])
      .then(([image, thumbnail]) => {
        if (!cancel) {
          setImage(image);
          setUrl(thumbnail);
        }
      })
      .catch(console.log)
      .finally(() => {
        if (!cancel) {
          setImageLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, [getImage, getThumbnail, props.id, props.shouldShowImage]);

  return (
    <ProjektLink to={`/events/${props.id}`}>
      <ProjektItemStyled>
        <ProjektItemImageWrapper>
          {url.length > 0 && !imageLoading && (
            <ProjektItemImage
              src={url}
              alt={props.title}
              onLoad={() => {
                if (image === null) {
                  return;
                }
                if (url === image) {
                  return;
                }
                setUrl(image);
              }}
            />
          )}
          {url.length === 0 && !imageLoading && <ProjektItemNoImage />}
          {imageLoading && <ProjektItemImageLoading />}
          <ProjektItemImageOverlay />
        </ProjektItemImageWrapper>
        <ProjektItemBody>
          <h1>{props.title}</h1>
          <h4>{props.description}</h4>
        </ProjektItemBody>
      </ProjektItemStyled>
    </ProjektLink>
  );
};

const Events: FC = () => {
  const { events } = useEvents();
  const [addEventModal, setAddEventModal] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const { isMobile } = useBreakpoint();

  const slideRight = () => {
    if (slideIndex + 1 > events.length - 1) {
      return;
    }
    setSlideIndex(slideIndex + 1);
  };

  const slideLeft = () => {
    if (slideIndex === 0) {
      return;
    }
    setSlideIndex(slideIndex - 1);
  };

  return (
    <Fragment>
      <PanelCaption to={"/events"} onAdd={() => setAddEventModal(true)}>
        Unsere Events
      </PanelCaption>
      {!isMobile && (
        <ProjektWrapper ref={wrapperRef}>
          {events.slice(0, 10).map((event, index) => (
            <ProjektItem
              {...event}
              index={index}
              slideIndex={slideIndex}
              key={event.id}
              events={events}
            />
          ))}
          {slideIndex + 1 < events.length - 1 && (
            <ProjektSliderButton
              aria-label={"nextEvent"}
              right
              onClick={slideRight}
              type={"button"}
            >
              <ChevronRightIcon
                width={30}
                height={30}
                fontWeight={"bold"}
                color={"#fff"}
              />
            </ProjektSliderButton>
          )}
          {slideIndex > 0 && (
            <ProjektSliderButton
              aria-label={"previousEvent"}
              left
              onClick={slideLeft}
              type={"button"}
            >
              <ChevronLeftIcon
                width={30}
                height={30}
                fontWeight={"bold"}
                color={"#fff"}
              />
            </ProjektSliderButton>
          )}
        </ProjektWrapper>
      )}
      {isMobile && (
        <Fragment>
          <ProjektMobileWrapper>
            {events.slice(0, 3).map((event) => (
              <MoblileProjektItem {...event} key={event.id} />
            ))}
          </ProjektMobileWrapper>
          <ProjektShowAllWrapper>
            <ProjektMobileShowAll to={"/events"}>
              Alle events anzeigen
            </ProjektMobileShowAll>
          </ProjektShowAllWrapper>
        </Fragment>
      )}
      <CreateEventModal
        open={addEventModal}
        onClose={() => setAddEventModal(false)}
      />
    </Fragment>
  );
};

const ProjektComponent: FC<HeroProjektComponentProps> = (props) => {
  const navigate = useNavigate();

  const { isMobile } = useBreakpoint();

  const isCurrentIndex = useMemo(
    () => props.i === props.index,
    [props.i, props.index]
  );

  const handleProjektClick = () => {
    props.setIndex(props.i);
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(() => {
      props.setIndex((prev) => (prev + 1) % props.images.length);
    }, props.INTERVAL);
  };

  return (
    <HeroProjektWrapper onClick={handleProjektClick}>
      <Wrapper>
        <Background>
          {isCurrentIndex && (
            <RedBar
              initial={{ width: 0 }}
              animate={{
                width: "100%",
                transition: {
                  duration: props.INTERVAL / 1000,
                },
              }}
            />
          )}
        </Background>
        {!isMobile && (
          <Description
            animate={{
              color: isCurrentIndex
                ? "rgba(255,255,255,1)"
                : "rgba(255,255,255,0.8)",
            }}
          >
            {props.projekt.description}
          </Description>
        )}
      </Wrapper>
      <HeroBannerOverlayStyled
        height={props.HEIGHT}
        mobileHeight={props.MOBILE_HEIGHT}
      />
      <AnimatePresence>
        {isCurrentIndex && (
          <HeroImage
            isCurrentIndex={isCurrentIndex}
            height={props.HEIGHT}
            mobileHeight={props.MOBILE_HEIGHT}
            src={props.images[props.i]}
            alt={props.projekt.title}
            {...HeroImageProps(props.INTERVAL)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCurrentIndex && (
          <HeroLink
            onClick={() => navigate("/projekte/" + props.projekt.id)}
            style={{ left: props.left, bottom: props.bottom }}
            {...HeroLinkTextProps(isCurrentIndex)}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.25 },
            }}
          >
            Zum Projekt
          </HeroLink>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCurrentIndex && (
          <HeroProjektTitle
            style={{ left: props.left, bottom: props.bottom }}
            {...HeroTitleProps(isCurrentIndex)}
          >
            {props.projekt.title}
          </HeroProjektTitle>
        )}
      </AnimatePresence>
    </HeroProjektWrapper>
  );
};

const HomeHero: FC<HomeHeroProps> = ({ MOBILE_HEIGHT, HEIGHT, INTERVAL }) => {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState<string[]>([]);
  const { projekte, getImage } = useProjekte();
  const { neccecary } = useCookies();
  const [loading, setLoading] = useState(true);
  const bottom = useMotionValue(0);
  const left = useMotionValue(0);
  const container = useRef<HTMLDivElement>(null);
  const { isMobile } = useBreakpoint();

  useEffect(() => {
    let cancel = false;

    const handleResize = () => {
      if (container.current) {
        if (!cancel) {
          left.set(container.current.offsetLeft);
          bottom.set(container.current.offsetHeight);
        }
      }
    };

    window.addEventListener("resize", handleResize, true);

    return () => {
      cancel = true;
      window.removeEventListener("resize", handleResize);
    };
  }, [bottom, left]);

  useEffect(() => {
    if (!neccecary) {
      return;
    }

    let cancel = false;

    const promises = projekte
      .filter((projekt) => projekt.isHero)
      .map((projekt) => getImage(projekt.id));

    Promise.all(promises)
      .then((images) => {
        if (cancel) {
          return;
        }
        setImages(images);
        if (timer) {
          clearInterval(timer);
        }
        timer = setInterval(() => {
          setIndex((prev) => (prev + 1) % images.length);
        }, INTERVAL);
      })
      .catch(console.log)
      .finally(() => {
        if (!cancel) {
          setLoading(false);
        }
      });

    return () => {
      cancel = true;
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [INTERVAL, getImage, neccecary, projekte]);

  return (
    <HeroStyled height={HEIGHT} mobileHeight={MOBILE_HEIGHT}>
      {loading ? (
        <HeroLoading HEIGHT={HEIGHT} MOBILE_HEIGHT={MOBILE_HEIGHT}>
          <LoadingBox
            width={isMobile ? 50 : 200}
            height={isMobile ? 50 : 200}
            color={"#001c39"}
          />
        </HeroLoading>
      ) : (
        <Container>
          <HeroContainerComponent
            onLoad={(event) => {
              left.set(event.currentTarget.getBoundingClientRect().left);
              bottom.set(event.currentTarget.offsetHeight);
            }}
            ref={container}
          >
            {projekte
              .filter((projekt) => projekt.isHero)
              .map((projekt, i) => (
                <ProjektComponent
                  left={left}
                  projekt={projekt}
                  i={i}
                  index={index}
                  images={images}
                  setIndex={setIndex}
                  key={projekt.id}
                  HEIGHT={HEIGHT}
                  MOBILE_HEIGHT={MOBILE_HEIGHT}
                  INTERVAL={INTERVAL}
                  bottom={bottom}
                />
              ))}
          </HeroContainerComponent>
        </Container>
      )}
    </HeroStyled>
  );
};

const HeroLoadingWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "mobileHeight",
})<{ height: number; mobileHeight: number }>(({ height, mobileHeight }) => ({
  width: "100%",
  height,
  "@media (max-width: 768px)": {
    height: mobileHeight,
  },
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  jusitfyContent: "center",
  backgroundColor: "#001c39",
}));

const HeroLoading: FC<HeroLoadingProps> = ({
  children,
  HEIGHT,
  MOBILE_HEIGHT,
}) => {
  return (
    <HeroLoadingWrapper mobileHeight={MOBILE_HEIGHT} height={HEIGHT}>
      {children}
    </HeroLoadingWrapper>
  );
};

const Home: FC = () => {
  const INTERVAL = getValue(getRemoteConfig(), "HOME_HERO_INTERVAL").asNumber();
  const HEIGHT = getValue(getRemoteConfig(), "HOME_HERO_HEIGHT").asNumber();
  const MOBILE_HEIGHT =
    getValue(getRemoteConfig(), "BANNER_MOBILE_HEIGHT").asNumber() + 100;

  return (
    <Fragment>
      <HomeHero
        HEIGHT={HEIGHT}
        MOBILE_HEIGHT={MOBILE_HEIGHT}
        INTERVAL={INTERVAL}
      />
      <Container>
        <Events />
        <Projekte
          HEIGHT={HEIGHT}
          MOBILE_HEIGHT={MOBILE_HEIGHT}
          INTERVAL={INTERVAL}
        />
        <Sponsoren />
      </Container>
    </Fragment>
  );
};

const ProjektSliderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "left" && prop !== "right",
})<{ right?: boolean; left?: boolean }>(({ left, right }) => ({
  backgroundColor: "rgba(0,0,0,0.3)",
  backdropFilter: "blur(10px)",
  border: "none",
  zIndex: 1,
  position: "absolute",
  top: "50%",
  borderRadius: 99999,
  ...(right && {
    right: 20,
  }),
  ...(left && {
    left: 20,
  }),
  transform: "translateY(-50%)",
  width: 70,
  height: 70,
}));

const ProjektItemImageWrapper = styled("div")(() => ({
  height: "60%",
  position: "relative",
  maskBorder: `url(${mask}) 12 stretch`,
  maskBorderWidth: "0 12px 12px 12px",
  WebkitMaskBoxImage: `url(${mask}) 12 stretch`,
  WebkitMaskBoxImageWidth: "0 12px 12px 12px",
}));

const ProjektWrapper = styled(Container)(() => ({
  position: "relative",
  width: "100%",
  height: 400,
}));

const ProjektMobileWrapper = styled(Container)(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%!important",
}));

const ProjektItemBody = styled("div")(() => ({
  backgroundColor: "#fff",
  padding: 20,
  height: "40%",
  maskBorder: `url(${mask}) 12 stretch`,
  maskBorderWidth: "0 12px 12px 12px",
  WebkitMaskBoxImage: `url(${mask}) 12 stretch`,
  WebkitMaskBoxImageWidth: "12px 12px 0 12px",
  h1: {
    margin: 0,
  },
  "@media (max-width: 768px)": {
    h1: {
      margin: 0,
    },
  },
}));

const ProjektShowAllWrapper = styled("div")(() => ({
  padding: "1rem 0",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

const ProjektMobileShowAll = styled(RouterLink)(() => ({
  textDecoration: "none",
  color: "#333",
  textAlign: "center",
  fontWeight: "bold",
  border: "1px solid #333",
  borderRadius: 30,
  padding: "0.5rem 1rem",
}));

const ProjektLink = styled(RouterLink)(() => ({
  textDecoration: "none",
  color: "#000",
  display: "flex",
  flexDirection: "row",
}));

const ProjekteWrapper = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
  "@media (max-width: 1200px)": {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  "@media (max-width: 768px)": {
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
  },
  gap: "1rem",
  marginBottom: "5rem",
}));

const ShowAllWrapper = styled("div")(() => ({
  padding: "1rem 0",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}));

const MobileShowAll = styled(RouterLink)(() => ({
  textDecoration: "none",
  color: "#333",
  textAlign: "center",
  fontWeight: "bold",
  border: "1px solid #333",
  borderRadius: 30,
  padding: "0.5rem 1rem",
}));

const ProjektItemStyled = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== "left",
})<{ left?: number }>(({ left }) => ({
  position: "absolute",
  top: 0,
  left,
  width: 300,
  height: "100%",
  "@media (max-width: 768px)": {
    position: "relative",
    width: "100%",
    left: "unset",
    top: "unset",
  },
  borderRadius: 20,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
}));

const ProjektItemImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}));

const ProjektItemNoImage = styled("div")(() => ({
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.2)",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}));

const ProjektItemImageOverlay = styled("div")(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.2)",
}));

const HeroBannerOverlayStyled = styled("div", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "mobileHeight",
})<{
  height: number;
  mobileHeight: number;
}>(({ height, mobileHeight }) => ({
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  height,
  zIndex: -1,
  background:
    "linear-gradient(180deg, rgba(0,15,30,0) 0%, rgba(0,15,30,0.5) 34%, rgba(0,15,30,0.8) 100%)",
  "@media (max-width: 768px)": {
    height: mobileHeight,
  },
}));

const HeroStyled = styled("div", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "mobileHeight",
})<{ height: number; mobileHeight: number }>(({ height, mobileHeight }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  alignItems: "center",
  "@media (max-width: 768px)": {
    height: mobileHeight,
  },
}));

const HeroContainerComponent = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  gap: "1rem",
  overflow: "hidden",
}));

const HeroProjektWrapper = styled("div")(() => ({
  width: "100%",
  height: "100%",
}));

const HeroProjektTitle = styled(motion.h1)(() => ({
  fontSize: 50,
  color: "rgb(255,255,255)",
  margin: 0,
  position: "absolute",
  maxWidth: "80%",
  "@media (max-width: 768px)": {
    fontSize: 30,
  },
}));

const HeroLink = styled(motion.button)(() => ({
  position: "absolute",
  borderRadius: 30,
  border: "none",
  backgroundColor: "rgb(255,0,0)",
  color: "rgb(255,255,255)",
  padding: "1rem 1.5rem",
  "@media (max-width: 768px)": {
    padding: "0.8rem 1rem",
  },
  cursor: "pointer",
  fontSize: 12,
  fontWeight: 600,
}));

const HeroImage = styled(motion.img, {
  shouldForwardProp: (prop) =>
    prop !== "height" && prop !== "mobileHeight" && prop !== "isCurrentIndex",
})<{ height: number; mobileHeight: number; isCurrentIndex: boolean }>(
  ({ height, isCurrentIndex, mobileHeight }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height,
    "@media (max-width: 768px)": {
      height: mobileHeight,
    },
    objectFit: "cover",
    zIndex: isCurrentIndex ? -2 : -3,
    verticalAlign: "middle",
    objectPosition: "top",
  })
);

const Description = styled(motion.p)(() => ({
  margin: "1rem 0",
}));

const Background = styled(motion.div)(() => ({
  width: "100%",
  height: 2,
  position: "relative",
  backgroundColor: "rgba(255,255,255,0.5)",
}));

const RedBar = styled(motion.div)(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: 2,
  backgroundColor: "rgb(255,0,0)",
}));

const Wrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    paddingBottom: "1rem",
  },
}));

export default Home;
