import { AnimatePresence } from "framer-motion";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Backdrop from "../components/modal/Backdrop";
import Modal from "../components/modal/Modal";
import Portal from "../components/page/Portal";
import Button from "../components/Button";
import { XMarkIcon } from "@heroicons/react/24/solid";
import styled from "@emotion/styled";
import Switch from "../components/forms/Switch";

type IUseCookies = {
  neccecary: boolean;
  performance: boolean;
  marketing: boolean;
  setShowCookieSettings: Dispatch<SetStateAction<boolean>>;
  showCookieSettings: boolean;
  toggleNeccecary: (value: boolean) => void;
  togglePerformance: (value: boolean) => void;
  toggleMarketing: (value: boolean) => void;
};

const CookieContext = createContext({} as IUseCookies);
export const useCookies = () => useContext(CookieContext);
const useCookiesProvider = (): IUseCookies => {
  const [neccecary, setNeccecary] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [showCookieSettings, setShowCookieSettings] = useState(false);

  useEffect(() => {
    const localNeccesary = localStorage.getItem("cookieNeccecary");
    const localPerformance = localStorage.getItem("cookiePerformance");
    const localMarketing = localStorage.getItem("cookieMarketing");

    if (localNeccesary && localNeccesary === "true") {
      setNeccecary(true);
    } else {
      setNeccecary(false);
    }
    if (localPerformance && localPerformance === "true") {
      setPerformance(true);
    } else {
      setPerformance(false);
    }
    if (localMarketing && localMarketing === "true") {
      setMarketing(true);
    } else {
      setMarketing(false);
    }

    if (!localNeccesary || localNeccesary === "false") {
      setShowCookieSettings(true);
    }
  }, []);

  const toggleNeccecary = (value: boolean) => {
    setNeccecary(value);
    localStorage.setItem("cookieNeccecary", value ? "true" : "false");
  };

  const togglePerformance = (value: boolean) => {
    setPerformance(value);
    localStorage.setItem("cookiePerformance", value ? "true" : "false");
  };

  const toggleMarketing = (value: boolean) => {
    setMarketing(value);
    localStorage.setItem("cookieMarketing", value ? "true" : "false");
  };

  return {
    neccecary,
    performance,
    marketing,
    showCookieSettings,
    setShowCookieSettings,
    toggleNeccecary,
    togglePerformance,
    toggleMarketing,
  };
};

const SettingWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "borderTop",
})<{ borderTop: boolean }>(({ borderTop }) => ({
  borderWidth: 1,
  borderColor: "rgba(0,0,0,0.3)",
  borderStyle: "solid",
  borderTopWidth: borderTop ? 1 : 0,
  padding: "1rem",
}));

const SettingHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
}));

const SettingText = styled("p")(() => ({
  margin: "1rem 0 0 0",
  color: "rgba(0,0,0,0.6)",
  fontSize: 11,
}));

const SettingTitle = styled("h5")(() => ({ margin: 0, userSelect: "none" }));

const SettingAlwaysActive = styled("p")(() => ({
  margin: 0,
  userSelect: "none",
}));

const Setting: FC<{
  title: string;
  children: ReactNode;
  value: boolean;
  alwaysActive?: boolean;
  borderTop: boolean;
  onChange: (value: boolean) => void;
}> = ({ title, children, value, onChange, alwaysActive, borderTop }) => {
  const [open, setOpen] = useState(false);

  return (
    <SettingWrapper borderTop={borderTop}>
      <SettingHeader onClick={() => setOpen(!open)}>
        <SettingTitle>{title}</SettingTitle>
        {alwaysActive ? (
          <SettingAlwaysActive>Immer aktiv</SettingAlwaysActive>
        ) : (
          <Switch
            variant={"dark"}
            width={50}
            height={30}
            value={value}
            onChange={onChange}
          />
        )}
      </SettingHeader>
      {open && <SettingText>{children}</SettingText>}
    </SettingWrapper>
  );
};

const SectionTitle = styled("h5")(() => ({
  margin: 0,
  userSelect: "none",
  color: "rgba(0,0,0,0.8)",
  marginBottom: "1rem",
}));

const Text = styled("p")(() => ({
  fontSize: 12,
  margin: "0 0 1rem 0",
}));

const CookiesBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingBottom: "2rem",
}));

const CookiesHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingTop: "1rem",
}));

const CookieFooter = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",
  marginTop: "1rem",
}));

export const CookiesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useCookiesProvider();

  useEffect(() => {
    if (!value.neccecary) {
      document.body.style.overflowY = "hidden";
      document.body.style.overflowX = "hidden";
    } else {
      if (value.showCookieSettings) {
        document.body.style.overflowY = "hidden";
        document.body.style.overflowX = "hidden";
      } else {
        document.body.style.overflowY = "auto";
        document.body.style.overflowX = "hidden";
      }
    }
  }, [value.neccecary, value.showCookieSettings]);

  return (
    <CookieContext.Provider value={value}>
      {children}
      <Portal>
        <AnimatePresence>
          {value.showCookieSettings && (
            <Backdrop onClick={() => value.setShowCookieSettings(false)}>
              <Modal>
                <CookiesBody>
                  {value.neccecary && (
                    <CookiesHeader>
                      <Button
                        type={"button"}
                        onClick={() => value.setShowCookieSettings(false)}
                      >
                        <XMarkIcon color={"#000"} width={20} height={20} />
                      </Button>
                    </CookiesHeader>
                  )}
                  <SectionTitle
                    style={{ marginTop: value.neccecary ? 0 : "2rem" }}
                  >
                    Datenschutz-Präferenz-Center
                  </SectionTitle>
                  <Text>
                    Wenn Du unsere Website besuchst, können wir Informationen
                    auf Deinem Browser speichern oder Informationen von Deinem
                    Browser abrufen. Wir tun dies, um Informationen über Dich,
                    Deine Präferenzen und Dein Gerät zu sammeln. Diese
                    Informationen werden zu Marketingzwecken verwendet, um die
                    Website besser zu gestalten und um Dir ein
                    personalisierteres Web-Erlebnis zu bieten. Wir setzen keine
                    optionalen Cookies, es sei denn, Du aktivierst sie. Klick
                    auf die verschiedenen Kategorieüberschriften, um mehr zu
                    erfahren und unsere Standard-Cookie-Einstellungen zu ändern.
                    Bitte beachte, dass das Blockieren einiger Arten von Cookies
                    Deine Nutzung der Website und die Dienste, die wir Dir
                    anbieten können, beeinträchtigen kann.
                  </Text>
                  <SectionTitle>
                    Einwilligungspräferenzen verwalten
                  </SectionTitle>
                  <Setting
                    borderTop={true}
                    title={"Unbeding erfolderliche Cookies"}
                    value={true}
                    alwaysActive={true}
                    onChange={(value) => {}}
                  >
                    Diese Cookies sind notwendig, damit die Website funktioniert
                    und um ihre Kernfunktionen, wie z.B. den Zugang zu sicheren
                    Bereichen, zur Verfügung zu stellen. Ohne diese Cookies
                    können wir einige Dienstleistungen, wie z.B. Warenkörbe und
                    sichere Kundenkontoseiten, nicht anbieten.
                  </Setting>
                  <Setting
                    borderTop={false}
                    title={"Performance Cookies"}
                    value={value.performance}
                    onChange={value.togglePerformance}
                  >
                    Diese Cookies sammeln Informationen darüber, wie eine
                    Website genutzt wird, z.B. welche Seiten besucht werden. Sie
                    helfen uns zu wissen, welche Seiten am beliebtesten sind und
                    wie sich Besucher auf der Website bewegen. Wenn diese
                    Cookies nicht zugelassen werden, wissen wir nicht, wann
                    unsere Website besucht wurde, und können die Leistung
                    unserer Website nicht überwachen. Es handelt sich dabei um
                    dauerhafte Cookies, die oft von einer dritten Partei und
                    nicht von uns selbst verwaltet werden.
                  </Setting>
                  <Setting
                    borderTop={false}
                    title={"Marketing Cookies"}
                    value={value.marketing}
                    onChange={value.toggleMarketing}
                  >
                    Wir verwenden Cookies, um Online-Aktivitäten zu verfolgen,
                    damit Werbetreibende relevantere Werbung liefern können oder
                    um zu begrenzen, wie oft eine Anzeige gesehen wird. Diese
                    Cookies können diese Informationen mit anderen
                    Organisationen oder Werbetreibenden teilen. Es handelt sich
                    dabei um dauerhafte Cookies, die fast immer von einer
                    dritten Partei und nicht von uns selbst betrieben werden.
                  </Setting>
                  <CookieFooter>
                    <Button
                      type={"button"}
                      onClick={() => {
                        value.setShowCookieSettings(false);
                        value.togglePerformance(false);
                        value.toggleMarketing(false);
                        value.toggleNeccecary(true);
                      }}
                    >
                      Alle ablehnen
                    </Button>
                    <Button
                      type={"button"}
                      onClick={() => {
                        value.setShowCookieSettings(false);
                        value.toggleNeccecary(true);
                      }}
                    >
                      Meine Auswahl bestätigen
                    </Button>
                  </CookieFooter>
                </CookiesBody>
              </Modal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    </CookieContext.Provider>
  );
};
