import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "./Cookie";
import { useAuth } from "./AuthProvider";
import {
  ArrayMinSize,
  IsArray,
  IsString,
  IsUrl,
  MinLength,
  ValidateIf,
  ValidateNested,
} from "class-validator";
import { Type } from "class-transformer";
import { CalendarEvent } from "calendar-link";
import moment from "moment/moment";

type IUseEvents = {
  events: Event[];
  getImage: (id: string) => Promise<string>;
  createEvent: (event: Event, image?: File) => Promise<void>;
  getThumbnail: (id: string) => Promise<string>;
  updateEvent: (event: Event, image?: File) => Promise<void>;
  deleteEvent: (event: Event) => Promise<void>;
  deleteBaseImage: (event: Event) => Promise<void>;
  generateCalendarEvent: (event: Event, index: number) => CalendarEvent;
};

export class Event {
  id: string;

  @IsString({ message: "Titel muss ein String sein" })
  @MinLength(3, { message: "Titel muss mindestens 3 Zeichen lang sein" })
  title: string;

  @IsString({ message: "Untertitel muss ein String sein" })
  @MinLength(3, { message: "Untertitel muss mindestens 3 Zeichen lang sein" })
  subtitle: string;

  @IsString({ message: "Beschreibung muss ein String sein" })
  @MinLength(3, { message: "Beschreibung muss mindestens 3 Zeichen lang sein" })
  description: string;

  @ValidateIf((o) => o.locationUrl !== undefined)
  @IsUrl({ require_protocol: true }, { message: "Ort muss eine URL sein" })
  locationUrl?: string;

  @IsArray({ message: "Zeiten muss ein Array sein" })
  @Type(() => EventTime)
  @ValidateNested({ each: true })
  @ArrayMinSize(1, { message: "Es muss mindestens eine Zeit angegeben werden" })
  times: EventTime[];

  shouldShowImage?: boolean;
}

export class EventTime {
  @IsString({ message: "Datum muss ein String sein" })
  date: string;

  @IsString({ message: "Start muss ein String sein" })
  start: string;

  @IsString({ message: "Ende muss ein String sein" })
  end: string;

  @IsString({ message: "Titel muss ein String sein" })
  @MinLength(3, { message: "Titel muss mindestens 3 Zeichen lang sein" })
  title: string;

  @IsString({ message: "Standort muss ein String sein" })
  @MinLength(3, { message: "Standort muss mindestens 3 Zeichen lang sein" })
  location: string;

  @IsString({ message: "Beschreibung muss ein String sein" })
  @MinLength(10, {
    message: "Beschreibung muss mindestens 10 Zeichen lang sein",
  })
  description: string;
}

const EventsContext = createContext({} as IUseEvents);
export const useEvents = () => useContext(EventsContext);
const useEventsProvider = (): IUseEvents => {
  const [events, setEvents] = useState<Event[]>([]);
  const { neccecary } = useCookies();
  const { loggedIn } = useAuth();

  useEffect(() => {
    if (!neccecary) {
      return;
    }

    const unsubscribeEvents = onSnapshot(
      collection(getFirestore(), "events"),
      (snapshot) => {
        const events: Event[] = [];
        snapshot.forEach((doc) => {
          events.push({
            id: doc.id,
            ...doc.data(),
          } as Event);
        });
        setEvents(events);
      }
    );

    return () => {
      unsubscribeEvents();
    };
  }, [loggedIn, neccecary]);

  const getImage = useCallback(
    async (id: string) => {
      if (!neccecary) {
        throw new Error("Not logged in");
      }
      return getDownloadURL(ref(getStorage(), `events/${id}/${id}.jpg`));
    },
    [neccecary]
  );

  const getThumbnail = useCallback(
    async (id: string) => {
      if (!neccecary) {
        throw new Error("Not logged in");
      }
      return getDownloadURL(
        ref(getStorage(), `events/${id}/${id}_200x200.jpg`)
      );
    },
    [neccecary]
  );

  const createEvent = useCallback(async (event: Event, image?: File) => {
    const firestore = getFirestore();

    const newDoc = await addDoc(collection(firestore, "events"), {
      title: event.title,
      subtitle: event.subtitle,
      description: event.description,
      shouldShowImage: false,
      ...(event.locationUrl !== undefined && {
        locationUrl: event.locationUrl,
      }),
      times: event.times,
    });
    if (image) {
      const storage = getStorage();
      const storageRef = ref(storage, `events/${newDoc.id}/${newDoc.id}.jpg`);
      await uploadBytes(storageRef, image);
      await updateDoc(doc(firestore, "events", newDoc.id), {
        shouldShowImage: true,
      });
    }
  }, []);

  const updateEvent = useCallback(async (event: Event, image?: File) => {
    const firestore = getFirestore();
    await updateDoc(doc(firestore, "events", event.id), {
      title: event.title,
      subtitle: event.subtitle,
      description: event.description,
      ...(event.locationUrl !== undefined && {
        locationUrl: event.locationUrl,
      }),
      times: event.times,
    });
    if (image) {
      const storage = getStorage();
      const storageRef = ref(storage, `events/${event.id}/${event.id}.jpg`);
      await uploadBytes(storageRef, image);
      await updateDoc(doc(firestore, "events", event.id), {
        shouldShowImage: true,
      });
    }
  }, []);

  const deleteEvent = useCallback(async (event: Event) => {
    const firestore = getFirestore();
    await deleteDoc(doc(firestore, "events", event.id));
    await deleteObject(ref(getStorage(), `events/${event.id}`));
  }, []);

  const deleteBaseImage = useCallback(async (event: Event) => {
    const storage = getStorage();
    const storageRef = ref(storage, `events/${event.id}/${event.id}.jpg`);
    await deleteObject(storageRef);
  }, []);

  const generateCalendarEvent = (
    event: Event,
    index: number
  ): CalendarEvent => {
    const date = moment(event.times[index].date);
    const start = moment(event.times[index].start, "HH:mm");
    const end = moment(event.times[index].end, "HH:mm");
    return {
      title: event.title,
      description: event.description,
      busy: true,
      location: event.times[index].location,
      allDay: false,
      start: date.hour(start.hour()).minute(start.minute()).toDate(),
      end: date.hour(end.hour()).minute(end.minute()).toDate(),
      duration: [moment.duration(end.diff(start)).asHours(), "hours"],
      url: "https://84til.de/events/" + event.id,
    };
  };

  return {
    deleteBaseImage,
    events,
    updateEvent,
    getImage,
    createEvent,
    getThumbnail,
    deleteEvent,
    generateCalendarEvent,
  };
};

export const EventsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const value = useEventsProvider();
  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};
