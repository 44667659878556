import {createContext, FC, ReactNode, useCallback, useContext, useEffect, useState} from "react";
import {useCookies} from "./Cookie";
import {addDoc, collection, getFirestore, onSnapshot} from "firebase/firestore";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import {IsNumber, IsString, MinLength, ValidateIf} from "class-validator";

export class Sponsor {
    id: string;
    @IsString({message: "Name muss ein String sein"})
    @MinLength(1, {message: "Name muss mindestens 1 Zeichen lang sein"})
    name: string;

    @ValidateIf((o) => o.imageScale !== undefined)
    @IsNumber({}, {message: "imageScale muss eine Zahl sein"})
    imageScale?: number;
}

type IUseSponsoren = {
    sponsoren: Sponsor[];
    getImage: (id: string) => Promise<string>;
    createSponsor: (sponsor: Sponsor, image?: File) => Promise<void>;
}

const SponsorenContext = createContext({} as IUseSponsoren);
export const useSponsoren = () => useContext(SponsorenContext);
const useSponsorenProvider = (): IUseSponsoren => {
    const [sponsoren, setSponsoren] = useState<Sponsor[]>([]);
    const {neccecary} = useCookies();

    useEffect(() => {
        if (!neccecary) {
            return
        }
        let cancel = false;

        const unsubscribe = onSnapshot(collection(getFirestore(), "sponsoren"), (snapshot) => {
            const newSponsoren: Sponsor[] = [];
            snapshot.forEach((doc) => {
                newSponsoren.push({
                    id: doc.id,
                    name: doc.data().name,
                    imageScale: doc.data().imageScale || 1
                })
            });
            if (!cancel) {
                setSponsoren(newSponsoren);
            }
        })

        return () => {
            cancel = true;
            unsubscribe();
        }
    }, [neccecary])

    const getImage = useCallback(async (id: string) => {
        const storage = getStorage();
        const imageRef = ref(storage, `sponsoren/${id}.jpg`);
        return getDownloadURL(imageRef);
    }, [])

    const createSponsor = useCallback(async (sponsor: Sponsor, image?: File) => {
        const createdDoc = await addDoc(collection(getFirestore(), "sponsoren"), {
            name: sponsor.name,
            imageScale: sponsor.imageScale || 1
        });
        if (image) {
            const storage = getStorage();
            const imageRef = ref(storage, `sponsoren/${createdDoc.id}.jpg`);
            await uploadBytes(imageRef, image)
        }
    }, []);

    return {
        sponsoren,
        getImage,
        createSponsor
    }
}
export const SponsorenProvider: FC<{ children: ReactNode }> = ({children}) => {
    const value = useSponsorenProvider();
    return (
        <SponsorenContext.Provider value={value}>
            {children}
        </SponsorenContext.Provider>
    )
}