import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import Portal from "./page/Portal";
import Backdrop from "./modal/Backdrop";
import Modal from "./modal/Modal";
import FileInput from "./forms/FileInput";
import Button from "./Button";
import { AnimatePresence } from "framer-motion";
import { Projekt, ProjektFile, useProjekte } from "../providers/Projekte";
import styled from "@emotion/styled";
import Input from "./forms/Input";

type Props = {
  open: boolean;
  onClose: () => void;
  projekt: Projekt;
  setFiles: Dispatch<SetStateAction<ProjektFile[]>>;
};

const Video = styled("video")(() => ({
  width: "100%",
  borderRadius: 20,
  height: 300,
  objectFit: "cover",
}));

const Image = styled("img")(() => ({
  width: "100%",
  borderRadius: 20,
  height: 300,
  objectFit: "cover",
}));

const Wrapper = styled("div")(() => ({
  display: "flex",
  padding: "1rem",
  flexDirection: "column",
  gap: "1rem",
}));

const UpdateProjektFilesModal: FC<Props> = ({
  open,
  onClose,
  projekt,
  setFiles,
}) => {
  const { loggedIn } = useAuth();
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileDescription, setFileDescription] = useState<string>("");
  const { uploadProjektVideo, uploadProjektImage, getProjektFiles } =
    useProjekte();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [open]);

  const handleClose = () => {
    if (fileUploading) {
      return;
    }
    onClose();
  };

  const handleUploadImage = async () => {
    if (file === undefined) {
      return;
    }
    setFileUploading(true);

    // check if image is video
    const video = file.type.includes("video");
    try {
      if (video) {
        await uploadProjektVideo(projekt.id, file, fileDescription);
      } else {
        await uploadProjektImage(projekt.id, file, fileDescription);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFileDescription("");
      const images = await getProjektFiles(projekt.id);
      setFiles(images);
      setFileUploading(false);
      setFile(undefined);
      onClose();
    }
  };

  if (loggedIn) {
    return (
      <Portal>
        <AnimatePresence>
          {open && (
            <Backdrop onClick={handleClose}>
              <Modal>
                <Wrapper>
                  <FileInput
                    accept={"image/*,video/*"}
                    onClear={() => {
                      setFile(undefined);
                    }}
                    onChange={(e) => {
                      if (e.target.files) {
                        setFile(e.target.files[0]);
                      }
                    }}
                    onSubmit={handleUploadImage}
                  />

                  {file !== undefined && file.type.includes("video") && (
                    <Video src={URL.createObjectURL(file)} controls />
                  )}
                  {file !== undefined && file.type.includes("image") && (
                    <Image src={URL.createObjectURL(file)} alt={"preview"} />
                  )}
                  <Input
                    value={fileDescription}
                    onChange={(e) => setFileDescription(e.currentTarget.value)}
                    placeholder={"Beschreibung"}
                    description={"Beschreibung des Bildes hinzufügen"}
                    onSubmit={handleUploadImage}
                  />
                  <Button
                    disabled={fileUploading || file === undefined}
                    size={"large"}
                    aria-label={"Datei hochladen"}
                    fullWidth
                    loading={fileUploading}
                    onClick={handleUploadImage}
                  >
                    Hochladen
                  </Button>
                </Wrapper>
              </Modal>
            </Backdrop>
          )}
        </AnimatePresence>
      </Portal>
    );
  }

  return null;
};

export default UpdateProjektFilesModal;
