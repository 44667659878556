import moment, { Moment } from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import Portal from "./page/Portal";
import Backdrop from "./modal/Backdrop";
import { AnimatePresence, motion } from "framer-motion";
import styled from "@emotion/styled";
import Button from "./Button";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

const Modal = styled(motion.div)(() => ({
  backgroundColor: "#fff",
  borderRadius: 30,
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
}));

const Month = styled("p")(() => ({
  fontWeight: "bold",
  fontSize: 14,
  margin: 0,
}));

const Navigation = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  minWidth: 600,
  padding: "1rem",
}));

const Headers = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 1rem 0.5rem 1rem",
}));

const Header = styled("p")(() => ({
  margin: 0,
  fontWeight: 400,
  fontSize: 12,
  textAlign: "center",
  flexGrow: 1,
}));

const Body = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(7, 1fr)",
  padding: "0 1rem",
}));

const Footer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "1rem",
}));

const Day = styled("button", {
  shouldForwardProp: (prop) => prop !== "previous" && prop !== "selected",
})<{ previous: boolean; selected: boolean }>(({ previous, selected }) => ({
  border: "none",
  backgroundColor: "transparent",
  fontWeight: 500,
  color: "#333",
  fontSize: 14,
  flexGrow: 1,
  padding: "1rem 0",
  textAlign: "center",
  cursor: "pointer",
  borderRadius: 8,
  ...(previous && {
    color: "rgba(0,0,0,0.5)",
  }),
  ...(!previous && {
    "&:hover": {
      backgroundColor: "#eee",
    },
  }),
  ...(selected && {
    backgroundColor: "#eee",
  }),
}));

type Props = {
  date: Moment | null;
  open: boolean;
  onClose: () => void;
  onDateChange: (date: Moment | null) => void;
};
const CalendarModal: FC<Props> = (props) => {
  const [month, setMonth] = useState<number>(moment().month());
  const [year, setYear] = useState<number>(moment().year());

  useEffect(() => {
    if (props.open) {
      window.document.body.style.overflowY = "hidden";
    } else {
      window.document.body.style.overflowY = "auto";
    }
  }, [props.open]);

  const handleMonthBack = () => {
    const date = moment().year(year).month(month).subtract(1, "month");
    setMonth(date.month());
    setYear(date.year());
  };

  const handleMonthForward = () => {
    const date = moment().year(year).month(month).add(1, "month");
    setMonth(date.month());
    setYear(date.year());
  };

  const prependingDays = useMemo(() => {
    const currentMonth = moment().year(year).month(month);
    const previousMonth = moment().year(year).month(month).subtract(1, "month");
    const firstDay = currentMonth.startOf("month").day();
    const prependingDays = [];
    for (let i = 0; i < firstDay - 1; i++) {
      prependingDays.push(previousMonth.daysInMonth() - (firstDay - i));
    }
    return prependingDays;
  }, [month, year]);

  const isSelected = (day: number): boolean => {
    if (props.date === null) {
      return false;
    }

    const date = moment().year(year).month(month).date(day);
    return date.isSame(props.date, "day");
  };

  const handleClick = (day: number) => {
    const date = moment().year(year).month(month).date(day);
    props.onDateChange(date);
    props.onClose();
  };

  const handleClear = () => {
    props.onDateChange(null);
    props.onClose();
  };

  return (
    <Portal>
      <AnimatePresence>
        {props.open && (
          <Backdrop onClick={props.onClose}>
            <Modal
              onClick={(e) => e.stopPropagation()}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Navigation>
                <Button
                  icon
                  aria-label={"Monat zurück"}
                  title={"Monat zurück"}
                  onClick={handleMonthBack}
                  type={"button"}
                >
                  <ChevronLeftIcon width={24} height={24} />
                </Button>
                <Month>
                  {moment().year(year).month(month).format("MMMM YYYY")}
                </Month>
                <Button
                  icon
                  aria-label={"Monat vorwärts"}
                  title={"Monat vorwärts"}
                  onClick={handleMonthForward}
                  type={"button"}
                >
                  <ChevronRightIcon width={24} height={24} />
                </Button>
              </Navigation>
              <Headers>
                <Header>Mo</Header>
                <Header>Di</Header>
                <Header>Mi</Header>
                <Header>Do</Header>
                <Header>Fr</Header>
                <Header>Sa</Header>
                <Header>So</Header>
              </Headers>
              <Body>
                {Array.from(
                  { length: prependingDays.length },
                  (_, i) => i + 1
                ).map((day) => (
                  <Day selected={false} key={day} previous>
                    {moment().year(year).month(month).daysInMonth() - day}
                  </Day>
                ))}
                {Array.from(
                  { length: moment().year(year).month(month).daysInMonth() },
                  (_, i) => i + 1
                ).map((day) => (
                  <Day
                    type={"button"}
                    aria-label={"Day " + day}
                    title={"Tag " + day + 1}
                    onClick={() => handleClick(day)}
                    selected={isSelected(day)}
                    previous={false}
                    key={day}
                  >
                    {day}
                  </Day>
                ))}
              </Body>
              <Footer>
                <Button
                  type={"button"}
                  onClick={handleClear}
                  aria-label={"Löschen"}
                  title={"Löschen"}
                  icon
                >
                  <XMarkIcon width={24} height={24} />
                  <span>Löschen</span>
                </Button>
              </Footer>
            </Modal>
          </Backdrop>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default CalendarModal;
