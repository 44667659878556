import { FC, Fragment, ReactNode, useMemo } from "react";
import styled from "@emotion/styled";
import { getRemoteConfig, getValue } from "firebase/remote-config";
import Button from "../Button";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import BannerImage from "./BannerImage";
import BannerOverlay from "./BannerOverlay";
import BannerTitle from "./BannerTitle";
import { useBreakpoint } from "../../providers/Breakpoint";

type Props = {
  children?: ReactNode;
  onDelete?: () => void;
  deleting?: boolean;
  onEdit?: () => void;
  background?: string;
  title?: string;
  subtitle?: string;
  content?: ReactNode;
  backgroundError?: boolean;
  onCreate?: () => void;
};

const Wrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "mobileHeight",
})<{ height: number; mobileHeight: number }>(({ height, mobileHeight }) => ({
  position: "relative",
  width: "100%",
  height,
  display: "flex",
  flexDirection: "column",
  "@media (max-width: 768px)": {
    height: mobileHeight,
  },
}));

const Controls = styled("div")(() => ({
  position: "absolute",
  right: "1rem",
  bottom: "1rem",
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  flexWrap: "nowrap",
  zIndex: 1,
}));

const BannerBackgroundError = styled("div", {
  shouldForwardProp: (prop) => prop !== "height",
})<{ height: number }>(({ height }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height,
  zIndex: -2,
  backgroundColor: "rgba(0,0,0,0.25)",
}));

const Banner: FC<Props> = (props) => {
  const height = getValue(getRemoteConfig(), "BANNER_HEIGHT").asNumber();
  const mobileHeight = getValue(
    getRemoteConfig(),
    "BANNER_MOBILE_HEIGHT"
  ).asNumber();
  const { isMobile } = useBreakpoint();

  const showControls = useMemo(() => {
    return (
      props.onDelete !== undefined ||
      props.onEdit !== undefined ||
      props.onCreate !== undefined
    );
  }, [props.onCreate, props.onDelete, props.onEdit]);

  return (
    <Wrapper height={height} mobileHeight={mobileHeight}>
      {props.children}
      {props.background !== undefined && !props.backgroundError && (
        <Fragment>
          <BannerImage src={props.background} />
          <BannerOverlay />
        </Fragment>
      )}
      {props.backgroundError && <BannerBackgroundError height={height} />}
      {props.title !== undefined && (
        <BannerTitle text={props.title} subtitle={props.subtitle}>
          {props.content}
        </BannerTitle>
      )}
      {showControls && (
        <Controls>
          {props.onEdit !== undefined && (
            <Button
              variant={"white"}
              type={"button"}
              aria-label={"edit"}
              title={"Bearbeiten"}
              onClick={props.onEdit}
              icon
            >
              <PencilIcon color={"#000"} width={14} height={14} />
              <span>Bearbeiten</span>
            </Button>
          )}
          {props.onCreate !== undefined && !isMobile && (
            <Button
              variant={"white"}
              type={"button"}
              aria-label={"create"}
              title={"Erstellen"}
              onClick={props.onCreate}
              icon
            >
              <PlusIcon color={"#000"} width={14} height={14} />
              <span>Erstellen</span>
            </Button>
          )}
          {props.onDelete !== undefined && (
            <Button
              variant={"danger"}
              type={"button"}
              aria-label={"delete"}
              title={"Löschen"}
              onClick={props.onDelete}
              icon
              loading={props.deleting}
              disabled={props.deleting}
            >
              <TrashIcon color={"#fff"} width={14} height={14} />
              Löschen
            </Button>
          )}
        </Controls>
      )}
    </Wrapper>
  );
};

export default Banner;
