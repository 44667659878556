import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "../../providers/AuthProvider";
import Input from "../forms/Input";
import Portal from "./Portal";
import Button from "../Button";
import Backdrop from "../modal/Backdrop";
import Modal from "../modal/Modal";
import { useForm } from "react-hook-form";
import { useBreakpoint } from "../../providers/Breakpoint";
import { useCookies } from "../../providers/Cookie";
import FormBody from "../forms/FormBody";
import { IsEmail, IsString, IsStrongPassword } from "class-validator";

const MenuMain = styled("div")(() => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}));

const MobileWrapper = styled(motion.header)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  padding: "1rem",
  backdropFilter: "blur(10px)",
  width: "100%",
  zIndex: 10,
}));

const HeaderStyled = styled(motion.header)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 10,
  willChange: "transform",
}));

const LinksWrapper = styled("div")(() => ({
  position: "absolute",
  top: "1rem",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  borderRadius: "30px",
  padding: "0 1rem",
  backgroundColor: "rgba(0,0,0,.4)",
  backdropFilter: "blur(20px)",
  border: "1px solid rgba(255,255,255,.5)",
}));

const LinkStyled = styled(RouterLink, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ active }) => ({
  textDecoration: "none",
  padding: "0.5rem 1rem",
  cursor: "pointer",
  display: "block",
  position: "relative",
  borderRadius: "30px",
  fontWeight: "bold",
  fontSize: 14,
  color: "rgba(255,255,255,.7)",
  "&:hover": {
    color: "#fff",
  },
  ...(active && {
    color: "rgba(255,255,255,1)",
  }),
}));

const LinkBackdrop = styled(motion.div)(() => ({
  position: "absolute",
  zIndex: -1,
  top: 0,
  left: 0,
  backgroundColor: "rgba(0,0,0,.3)",
  borderRadius: "30px",
  width: "100%",
  height: "100%",
}));

const LinkContent = styled("span")(() => ({
  zIndex: 1,
}));

const Link: FC<{ to: string; children: ReactNode; onClick: () => void }> = (
  props
) => {
  const [isHovered, setIsHovered] = useState(false);
  const { pathname } = useLocation();

  const active = useMemo(() => {
    if (props.to === "/") {
      return pathname === "/";
    }
    return pathname.startsWith(props.to) || pathname === props.to;
  }, [pathname, props.to]);

  return (
    <LinkStyled
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      to={props.to}
      active={active}
      onClick={props.onClick}
    >
      <AnimatePresence>
        {isHovered && (
          <LinkBackdrop
            transition={{ duration: 0.2 }}
            initial={{ scale: 0.8, opacity: 0.5 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0.1 }}
          />
        )}
      </AnimatePresence>
      <LinkContent>{props.children}</LinkContent>
    </LinkStyled>
  );
};

const MenuButton = styled("button")(() => ({
  borderRadius: "50%",
  border: "1px solid rgba(255,255,255,.5)",
  width: "36px",
  height: "36px",
  backgroundColor: "rgba(0,0,0,.4)",
  backdropFilter: "blur(20px)",
  transition: "background-color .2s ease-in-out",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,.6)",
  },
}));

const LogoWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.5rem",
  "@media (max-width: 480px)": {
    justifyContent: "space-between",
    width: "100%",
  },
}));

const MenuWrapper = styled(motion.div)(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  overflow: "hidden",
  backgroundColor: "#001c39",
  zIndex: 20,
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
}));

const Sidebar = styled("nav")(() => ({
  display: "flex",
  flexDirection: "column",
  width: 300,
  height: "100%",
  flexShrink: 0,
  alignItems: "flex-start",
  justifyContent: "center",
  gap: "1rem",
  a: {
    color: "#fff",
    fontSize: 40,
    "@media (max-height: 700px)": {
      fontSize: 30,
    },
    "@media (max-height: 550px)": {
      fontSize: 20,
    },
    span: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  "@media (max-width: 480px)": {
    width: "100%",
    justifyContent: "flex-start",
    paddingTop: 40,
  },
  "@media (max-height: 700px)": {
    paddingTop: 30,
  },
  "@media (max-height: 550px)": {
    paddingTop: 20,
  },
}));

const MenuContent = styled("div")(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  "@media (max-width: 480px)": {
    flexDirection: "column-reverse",
    paddingTop: 80,
  },
}));

const MenuHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "flex-start",
}));

class Login {
  @IsString({ message: "E-Mail angeben" })
  @IsEmail({}, { message: "Bitte gib eine valide E-Mail-Adresse an" })
  email: string;

  @IsString({ message: "Passwort angeben" })
  @IsStrongPassword(
    {
      minLength: 8,
      minLowercase: 1,
      minNumbers: 1,
      minUppercase: 1,
    },
    {
      message:
        "Das Passwort muss mindestens 8 Zeichen lang sein, einen Kleinbuchstaben, einen Großbuchstaben undmindestens eine Zahl beinhalten",
    }
  )
  password: string;
}

const Header: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrollOut, setScrollOut] = useState(false);
  const { loggedIn, signOut } = useAuth();
  const [loginModal, setLoginModal] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { neccecary, showCookieSettings } = useCookies();
  const [error, setError] = useState<string>("");
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<Login>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrollOut(true);
      } else {
        setScrollOut(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!neccecary) {
      document.body.style.overflowY = "hidden";
    } else {
      if (showCookieSettings) {
        document.body.style.overflowY = "hidden";
      } else {
        if (isMenuOpen) {
          document.body.style.overflowY = "hidden";
        } else {
          document.body.style.overflowY = "auto";
        }
      }
    }
  }, [neccecary, isMenuOpen, showCookieSettings]);

  const handleSignIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
      setLoginModal(false);
      setError("");
      reset();
    } catch (error: any) {
      if (error.message) {
        if (typeof error.message === "string") {
          if (error.message.includes("Firebase:")) {
            setError(error.message.split("Firebase: ")[1]);
          } else {
            setError(error.message);
          }
        } else {
          console.log(typeof error.message);
          setError(String(error.message));
        }
      }
    }
  };

  return (
    <Fragment>
      <Portal>
        <AnimatePresence>
          {isMenuOpen && (
            <MenuWrapper
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.25 },
              }}
              exit={{ opacity: 0 }}
              layout
            >
              <MenuHeader>
                <LogoWrapper>
                  <MenuButton
                    aria-label={"menu-close"}
                    type="button"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <XMarkIcon color={"white"} />
                  </MenuButton>
                  <h1 style={{ margin: 0, color: "#fff" }}>84TIL Logo</h1>
                </LogoWrapper>
              </MenuHeader>
              <MenuContent>
                <Sidebar>
                  <Link to={"/"} onClick={() => setIsMenuOpen(false)}>
                    Home
                  </Link>
                  <Link to={"/kontakt"} onClick={() => setIsMenuOpen(false)}>
                    Kontakt
                  </Link>
                  <Link to={"/projekte"} onClick={() => setIsMenuOpen(false)}>
                    Projekte
                  </Link>
                  <Link to={"/events"} onClick={() => setIsMenuOpen(false)}>
                    Events
                  </Link>
                  <Link to={"/kurse"} onClick={() => setIsMenuOpen(false)}>
                    Kurse
                  </Link>
                  <Link to={"/impressum"} onClick={() => setIsMenuOpen(false)}>
                    Impressum
                  </Link>
                  <Link
                    to={"/datenschutz"}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Datenschutz
                  </Link>
                </Sidebar>
                <MenuMain>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      aria-label={"Profil"}
                      variant={"clean"}
                      onClick={() => {
                        if (!loggedIn) {
                          setLoginModal(true);
                        } else {
                          setIsMenuOpen(false);
                          navigate("/profil");
                        }
                      }}
                    >
                      <UserIcon width={24} height={24} color={"#fff"} />
                    </Button>
                    {loggedIn && (
                      <Button
                        aria-label={"logout"}
                        variant={"clean"}
                        onClick={() => signOut()}
                      >
                        <ArrowRightOnRectangleIcon
                          color={"#fff"}
                          width={24}
                          height={24}
                        />
                      </Button>
                    )}
                  </div>
                </MenuMain>
              </MenuContent>
            </MenuWrapper>
          )}
        </AnimatePresence>
        {!loggedIn && (
          <AnimatePresence>
            {loginModal && (
              <Backdrop onClick={() => setLoginModal(false)}>
                <Modal>
                  <h3 style={{ margin: 0, padding: "1rem" }}>84til Login</h3>
                  <form
                    onSubmit={handleSubmit(handleSignIn)}
                    style={{ width: "100%" }}
                  >
                    <FormBody>
                      <Input
                        type={"email"}
                        placeholder={"E-Mail"}
                        {...register("email")}
                        errors={errors}
                        label={"E-Mail-Adresse"}
                      />
                      <Input
                        label={"Passwort"}
                        type={"password"}
                        placeholder={"Passwort"}
                        {...register("password")}
                        errors={errors}
                      />
                      {error.length > 0 && (
                        <p
                          style={{
                            margin: 0,
                            padding: 0,
                            textAlign: "center",
                            color: "red",
                            fontSize: 14,
                            fontWeight: "bold",
                          }}
                        >
                          {error}
                        </p>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "1rem",
                        }}
                      >
                        <Button
                          fullWidth
                          aria-label={"close-login"}
                          type={"button"}
                          disabled={isSubmitting}
                          onClick={() => setLoginModal(false)}
                        >
                          Abbrechen
                        </Button>
                        <Button
                          variant={"success"}
                          aria-label={"login"}
                          disabled={isSubmitting}
                          type={"submit"}
                          loading={isSubmitting}
                          size={"large"}
                          fullWidth
                        >
                          Einloggen
                        </Button>
                      </div>
                    </FormBody>
                  </form>
                </Modal>
              </Backdrop>
            )}
          </AnimatePresence>
        )}
      </Portal>
      {isMobile && (
        <MobileWrapper
          initial={{ y: 0 }}
          animate={{
            y: isScrollOut ? "-100%" : 0,
            transition: { stiffness: 200, damping: 50 },
          }}
        >
          <MenuButton
            aria-label={"Menu"}
            type="button"
            onClick={() => setIsMenuOpen(true)}
          >
            <Bars3Icon color={"white"} />
          </MenuButton>
          <h1 style={{ margin: 0, color: "#fff" }}>84TIL Logo</h1>
        </MobileWrapper>
      )}
      {!isMobile && (
        <HeaderStyled
          initial={{ y: 0 }}
          animate={{
            y: isScrollOut ? "-100%" : 0,
            transition: { stiffness: 200, damping: 50 },
          }}
        >
          <LogoWrapper>
            <MenuButton
              aria-label={"menu"}
              type="button"
              onClick={() => setIsMenuOpen(true)}
            >
              <Bars3Icon color={"white"} />
            </MenuButton>
            <h1 style={{ margin: 0, color: "#fff" }}>84TIL Logo</h1>
          </LogoWrapper>
          <LinksWrapper>
            <Link to={"/"} onClick={() => setIsMenuOpen(false)}>
              Home
            </Link>
            <Link to={"/kontakt"} onClick={() => setIsMenuOpen(false)}>
              Kontakt
            </Link>
            <Link to={"/projekte"} onClick={() => setIsMenuOpen(false)}>
              Projekte
            </Link>
            <Link to={"/events"} onClick={() => setIsMenuOpen(false)}>
              Events
            </Link>
            <Link to={"/kurse"} onClick={() => setIsMenuOpen(false)}>
              Kurse
            </Link>
          </LinksWrapper>
          <div></div>
        </HeaderStyled>
      )}
    </Fragment>
  );
};

export default Header;
