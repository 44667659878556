import {
  DetailedHTMLProps,
  forwardRef,
  ReactNode,
  TextareaHTMLAttributes,
} from "react";
import styled from "@emotion/styled";
import { FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ErrorText from "./ErrorText";

type Props = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  variant?: "white";
  trailingIcon?: ReactNode;
  errors?: FieldErrors<any>;
};

const TextareaStyled = styled("textarea", {
  shouldForwardProp: (prop) => prop !== "variant",
})<{
  variant?: "white";
}>(({ variant }) => ({
  padding: "1rem",
  borderRadius: 20,
  outline: "none",
  fontSize: 13,
  backgroundColor: "transparent",
  border: "none",
  width: "100%",
  fontFamily: "inherit",
  transition: "all 0.2s ease-in-out",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "rgba(0,0,0,0.3)",
  "&:focus": {
    borderColor: "rgba(0,0,0,1)",
  },
  ...(variant === "white" && {
    color: "#fff",
    "&:focus": {
      borderColor: "rgba(255,255,255,0.5)",
    },
  }),
  "&:disabled": {
    opacity: 0.5,
  },
}));

const Wrapper = styled("div")(() => ({ width: "100%" }));

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { trailingIcon, name, errors, ...rest } = props;
  return (
    <Wrapper>
      <TextareaStyled ref={ref} name={name} {...rest} />
      {name !== undefined && errors !== undefined && (
        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => <ErrorText>{message}</ErrorText>}
        />
      )}
    </Wrapper>
  );
});

export default Textarea;
