import styled from "@emotion/styled";
import {
  CSSProperties,
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useId,
} from "react";
import { FieldErrors } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ErrorText from "./ErrorText";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

const StyledInput = styled("input", {
  shouldForwardProp: (prop) => prop !== "varaint",
})<{ variant?: "white" }>(({ variant }) => ({
  paddingTop: "1rem",
  paddingBottom: "1rem",
  outline: "none",
  fontSize: 13,
  backgroundColor: "transparent",
  border: "none",
  width: "100%",
  fontFamily: "inherit",
  ...(variant === "white" && {
    color: "#fff",
  }),
  "::-webkit-search-decoration": {
    WebkitAppearance: "none",
  },
  "::-webkit-search-cancel-button": {
    WebkitAppearance: "none",
  },
  "::-webkit-search-results-button": {
    WebkitAppearance: "none",
  },
  "::-webkit-search-results-decoration": {
    WebkitAppearance: "none",
  },
}));

const Wrapper = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "varaint" && prop !== "wrapper768Style" && prop !== "wrapperStyle",
})<{
  variant?: "white";
  wrapper768Style?: CSSProperties;
  wrapperStyle?: CSSProperties;
}>(({ variant, wrapper768Style, wrapperStyle }) => ({
  paddingLeft: "1rem",
  paddingRight: "1rem",
  display: "flex",
  borderRadius: 30,
  height: 50,
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  transition: "all 0.2s ease-in-out",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "rgba(0,0,0,0.3)",
  backgroundColor: "transparent",
  width: "100%",
  "&:focus-within": {
    borderColor: "rgba(0,0,0,1)",
  },
  ...(variant === "white" && {
    backgroundColor: "transparent",
    borderColor: "rgba(255,255,255,0.3)",
    "&:focus-within": {
      borderColor: "rgba(255,255,255,0.5)",
    },
  }),
  "&:disabled": {
    opacity: 0.5,
  },
  ...wrapperStyle,
  "@media (max-width: 768px)": {
    ...wrapper768Style,
  },
}));

const Container = styled("div")(() => ({
  width: "100%",
  position: "relative",
}));

const Description = styled("p")(() => ({
  fontSize: 11,
  color: "rgba(0,0,0,0.5)",
  margin: "0.5rem 0 0 0",
  padding: "0 1rem",
}));

const Label = styled("label")(() => ({
  display: "block",
  fontSize: 13,
  fontWeight: "bold",
  color: "rgba(0,0,0,0.3)",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingBottom: 4,
}));

const InfoButton = styled("a")(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  padding: 0,
  border: "none",
  textDecoration: "none",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  variant?: "white";
  trailingIcon?: ReactNode;
  errors?: FieldErrors<any>;
  description?: string;
  label?: string;
  onInfoClick?: string;
  wrapperStyle?: CSSProperties;
  wrapper768Style?: CSSProperties;
};

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    trailingIcon,
    onInfoClick,
    label,
    name,
    errors,
    id,
    description,
    wrapperStyle,
    wrapper768Style,
    ...rest
  } = props;
  const newId = useId();

  return (
    <Container>
      {label !== undefined && <Label htmlFor={id || newId}>{label}</Label>}
      <Wrapper
        wrapper768Style={wrapper768Style}
        wrapperStyle={wrapperStyle}
        variant={props.variant}
      >
        <StyledInput id={id || newId} ref={ref} name={name} {...rest} />
        {trailingIcon !== undefined && trailingIcon}
      </Wrapper>
      {name !== undefined && errors !== undefined && (
        <ErrorMessage
          name={name}
          errors={errors}
          render={({ message }) => <ErrorText>{message}</ErrorText>}
        />
      )}
      {description !== undefined && <Description>{description}</Description>}
      {onInfoClick !== undefined && (
        <InfoButton
          rel={"noreferrer"}
          aria-label={"info"}
          href={onInfoClick}
          target={"_blank"}
          title={"Info"}
        >
          <InformationCircleIcon width={20} height={20} color={"#333"} />
        </InfoButton>
      )}
    </Container>
  );
});

export default Input;
